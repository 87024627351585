import PARTY_ACTION_TYPES from "./partiestypes";

export const PARTIES_INITIAL_STATE = {
  partiesLists: [],
};

export const PartiesReducer = (state = PARTIES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PARTY_ACTION_TYPES.LOAD_PARTIES: {
      return { ...state, partiesLists: payload };
    }
    default:
      return state;
  }
};
