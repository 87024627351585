import ADMIN_ACTION_TYPES from './admin.types';

export const ADMINS_INITIAL_STATE = {
  adminsLists: [],
};

export const AdminsReducer = (state = ADMINS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_ACTION_TYPES.LOAD_ADMIN_CHILDREN: {
      return { ...state, adminChildrenLists: payload };
    }
    case ADMIN_ACTION_TYPES.LOAD_ADMINS: {
      return { ...state, adminsLists: payload };
    }
    case ADMIN_ACTION_TYPES.DELETE_ADMIN: {
      return { ...state, adminsLists: state.adminsLists.filter((item, index) => index !== action.payload) };
    }
    default:
      return state;
  }
};
