import ACTIVE_EMPLOYEE_ACTION_TYPES from "./activeEmployees.types";

export const ACTIVE_EMPLOYEE_INITIAL_STATE = {
  activeEmployeeLists: [],
};

export const ActiveEmployeeReducer = (state = ACTIVE_EMPLOYEE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ACTIVE_EMPLOYEE_ACTION_TYPES.LOAD_LISTS: {
      return { ...state, activeEmployeeLists: payload };
    }
    default:
      return state;
  }
};
