import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import PartyTypesCreate from './PartyTypesCreate';
import PartyTypesUpdate from './PartyTypesUpdate';

const PartyTypes = Loadable(lazy(() => import('./PartyTypes')));

const partyTypesRoutes = [
  { path: '/party-types/', element: <PartyTypes />, auth: authRoles.admin },
  { path: '/party-types/create', element: <PartyTypesCreate />, auth: authRoles.admin },
  { path: '/party-types/edit/:partyTypeId', element: <PartyTypesUpdate />, auth: authRoles.admin },
];

export default partyTypesRoutes;
