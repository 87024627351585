import axiosClient from '../axios';

export const zonesList = () => axiosClient.get('zone');
export const zonesCreate = (data) => axiosClient.post('zone', data);
export const zonesUpdate = (id, data) => axiosClient.put(`zone/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const zonesUpdateStatus = (data) => axiosClient.post('zone-active', data);
export const zonesDelete = (id) => axiosClient.delete(`zone/${id}`);
