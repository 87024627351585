import { List, Stack, Table, TableCell, TableRow } from '@mui/material';
import { ListItemText } from '@mui/material';
import { Avatar } from '@mui/material';
import { ListItemAvatar } from '@mui/material';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { ListItem } from '@mui/material';
import { Box } from '@mui/material';
import { employeeDetail } from 'app/api/services/employees';
import React, { useEffect, useState } from 'react'


function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                // p: 1,
                m: 1,
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}


const EmployeeDetail = ({ data }) => {
    console.log(data);
    return (
        <Box
            sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                m: '0',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridTemplateRows: 'repeat(2, 50px)',
                gap: 1,
            }}
        >
            <Item sx={{ gridColumn: '1', gridRow: '1/ span 3' }}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Avatar sx={{ mx: 'auto', marginTop: '10px', width: 75, height: 75 }} alt={data.employee_name} src="/static/images/avatar/2.jpg" />
                    <Typography variant='h4' sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '1.5', paddingBottom: '10px' }} > {data.employee_name}</Typography>
                </Stack>
                <Divider />
                <Table>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Parent Name</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.parent.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Email</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.email}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Phone</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.phone_no}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Country</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.city.country_name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Zone</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.city.zone_name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>State</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.city.state_name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>City</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.city.name}</TableCell>
                    </TableRow>
                </Table>
            </Item>
            <Item sx={{ gridColumn: '2', gridRow: '1/ span 3' }}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant='h4' sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '1.5', padding: '10px' }} > Total Overview </Typography>
                </Stack>
                <Divider />
                <Table>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Total Parties</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.total_party}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Total Expense</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.total_expenses}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Total Approved Expense</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.total_approved_expenses}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Total Payments</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.total_payments_amount + ' (' + data.total_payments + ')'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Total Visits</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.total_visits}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <Typography variant='h4' sx={{ display: 'flex', justifyContent: 'center', fontSize: '16px', fontWeight: '500', lineHeight: '1.5' }} > Device Detail </Typography>

                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Device Model</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.device_model}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Device Id</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.device_id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Phone Number</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{data.phone_no}</TableCell>
                    </TableRow>
                </Table>
            </Item>
            <Item sx={{ gridColumn: '3', gridRow: '1/ span 3' }}>
                <Typography variant='h4' sx={{ display: 'flex', justifyContent: 'center', fontSize: '18px', fontWeight: '600', lineHeight: '1.7', padding: '10px' }} > Last Punch Detail </Typography>
                <Divider />
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >

                    <Typography variant='h4' sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '1.5', padding: '10px' }} > Punch In </Typography>
                </Stack>
                <Divider />
                <Table>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Punch In Date</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{(data.last_punch !== null) ? data.last_punch.punch_in_at : 'Not punched in yet!'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Punch In Meter Reading</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{(data.last_punch !== null) ? data.last_punch.punch_in_meter_reading : 'Not punched in yet!'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Punch In Meter Reading Photo</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{(data.last_punch !== null) ? data.last_punch.punch_in_meter_reading : 'Not punched in yet!'}</TableCell>
                    </TableRow>
                </Table>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant='h4' sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '1.5', padding: '10px' }} > Punch Out </Typography>
                </Stack>
                <Divider />
                <Table>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Punch Out Date</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{(data.last_punch !== null) ? data.last_punch.punch_out_at : 'Not punched in yet!'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Punch Out Meter Reading</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{(data.last_punch !== null) ? data.last_punch.punch_out_meter_reading : 'Not punched in yet!'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ paddingLeft: '10px' }}>Punch Out Meter Reading Photo</TableCell>
                        <TableCell sx={{ paddingLeft: '10px' }}>{(data.last_punch !== null) ? data.last_punch.punch_out_meter_reading : 'Not punched in yet!'}</TableCell>
                    </TableRow>
                </Table>
            </Item>
            {/* <Item sx={{ gridColumn: '3' }}>Device Detail</Item> */}
        </Box>
    )
}

export default EmployeeDetail