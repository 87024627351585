import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import GradesCreate from './GradesCreate';
import GradesUpdate from './GradesUpdate';

const Grades = Loadable(lazy(() => import('./CityGrades')));

const gradesRoutes = [
  { path: '/grades/', element: <Grades />, auth: authRoles.admin },
  { path: '/grades/create', element: <GradesCreate />, auth: authRoles.admin },
  { path: '/grades/edit/:gradeId', element: <GradesUpdate />, auth: authRoles.admin },
];

export default gradesRoutes;
