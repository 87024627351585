import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { statesList, statesUpdate } from "app/api/services/states";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setStatesList } from "app/redux/states/states.action";
import { selectStates } from "app/redux/states/states.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StatesForm from "./StatesForm";

const StatesUpdate = () => {
    const title = 'states';

    const [state, setState] = useState([]);

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { stateId } = useParams();
    const { updateSnackbar } = useSnackbar();

    
    const statesState = useSelector(selectStates);
    const states = statesState.map( item => {
            return item.name.toLowerCase()
        
    });
    
    const currentState = statesState.filter( item => item.id == stateId);
    const selected = { label: currentState[0].zone_name, id: currentState[0].id }
    
    useEffect(() => {
        try {
            statesList().then((res) => {
                dispatch(setStatesList(res.data.result.data));
                setState(states);
            });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    
    const handleSubmit = (state) => {
        
        const formData = {
            "name": state.state_name, 
            "zone_id": state.zone_id
        }

        statesUpdate(stateId, formData).then((res) => {
        
            updateSnackbar("success", "State updated successfully")
            navigate("/states");
        
        }).catch(function (error) {
        
            if (error.response) {
                console.log(error.response);
            }
        
        });
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "State Update" }]} />
            </Box>
            <SimpleCard title="States Form">
                <StatesForm handleSubmit={handleSubmit} tableData={{...currentState[0], default: selected}}/>
            </SimpleCard>
        </Container>
    );
};

export default StatesUpdate;
