import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { employeeActiveList } from "app/api/services/activeEmployee";
import { Breadcrumb } from "app/components";
import Map from "app/components/GoogleMap/Map";
import { setActiveEmployeesList } from "app/redux/activeEmployees/activeEmployees.action";
import { selectActiveEmployee } from "app/redux/activeEmployees/activeEmployees.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ActiveEmployeeView = () => {
    const title = 'Punch Log';

    const dispatch = useDispatch();

    const { punchId } = useParams();

    const [loading, setLoading] = useState(false);
    const [pins, setPins] = useState({});


    const punchState = useSelector(selectActiveEmployee);


    useEffect(() => {
        setLoading(true);

        try {
            employeeActiveList().then((res) => {
                dispatch(setActiveEmployeesList(res.data.result.data));
                setLoading(false);
            });
            const currentEmployeeLog = punchState.filter((item) =>
                item.id == punchId
            )
            setPins(currentEmployeeLog[0]['log'])

        } catch (error) {
            setLoading(false);
        }
    }, [])


    // const pins = currentEmployeeLog[0];

    //     pins.map(item => console.log(item))

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));



    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Admin Update" }]} />
            </Box>
            <div style={{ height: '100vh', width: '100%' }}>
                <Map Pins={pins} />
            </div>
        </Container>
    );
};

export default ActiveEmployeeView;
