import { authRoles } from "./auth/authRoles";

export const navigations = [
  { name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard', visible: authRoles.subAdmin },
  { name: 'Live Map', path: '/map/', icon: 'pin_drop', visible: authRoles.subAdmin },
  { label: 'USERS', type: 'label', visible: authRoles.subAdmin },
  {
    name: 'Admins',
    icon: 'person',
    visible: authRoles.subAdmin,
    children: [
      { name: 'List', iconText: 'L', path: '/admins/', visible: authRoles.subAdmin },
      { name: 'Tree View', iconText: 'TV', path: '/tree', visible: authRoles.subAdmin },
    ],
  },
  {
    name: 'Employees',
    icon: 'people',
    visible: authRoles.subAdmin,
    children: [
      { name: 'Employee List', iconText: 'EL', path: '/employees', visible: authRoles.subAdmin },
      { name: 'Employee Active', iconText: 'EA', path: '/active-employees', visible: authRoles.subAdmin },
      { name: 'Employee Report', iconText: 'ER', path: '/session/forgot-password', visible: authRoles.subAdmin },
    ],
  },
  {
    name: 'Parties',
    icon: 'perm_contact_calendar',
    visible: authRoles.subAdmin,
    children: [
      { name: 'Party List', iconText: 'PL', path: '/parties', visible: authRoles.subAdmin },
      { name: 'Party Categories', iconText: 'PC', path: '/party-categories', visible: authRoles.subAdmin },
      { name: 'Party Types', iconText: 'PT', path: '/party-types', visible: authRoles.subAdmin },
    ],
  },
  { label: 'Modules', type: 'label', visible: authRoles.subAdmin },
  {
    name: 'Expenses',
    icon: 'attach_money',
    visible: authRoles.subAdmin,
    children: [
      { name: 'Expense List', iconText: 'EL', path: '/employee-expenses', visible: authRoles.subAdmin },
      { name: 'Expense Categories', iconText: 'EC', path: '/expense-categories', visible: authRoles.subAdmin },
    ],
  },
  { name: 'Visits', path: '/employee-visit/', icon: 'explore', visible: authRoles.subAdmin },
  { name: 'Payments', path: '/payments/', icon: 'payment', visible: authRoles.subAdmin },

  { label: 'Region', type: 'label', visible: authRoles.subAdmin },
  { name: 'Countries', path: '/countries/', icon: 'public', visible: authRoles.subAdmin },
  { name: 'Zones', path: '/zones/', icon: 'public', visible: authRoles.subAdmin },
  { name: 'States', path: '/states', icon: 'map', visible: authRoles.subAdmin },
  { name: 'Cities', path: '/cities', icon: 'place', visible: authRoles.subAdmin },

  { label: 'Grades', type: 'label', visible: authRoles.subAdmin },
  { name: 'City Grades', path: '/grades', icon: 'my_location', visible: authRoles.subAdmin },
  { name: 'Employee Grades', path: '/employee-grades', icon: 'my_location', visible: authRoles.subAdmin },

  { label: 'Communication', type: 'label', visible: authRoles.subAdmin },
  { name: 'Message Scheduler', path: '/dashboard/default', icon: 'access_alarm', visible: authRoles.subAdmin },
  // { name: 'Notification', path: '/dashboard/default', icon: 'notifications', visible: authRoles.subAdmin },

  { label: 'HR Module', type: 'label', visible: authRoles.subAdmin },
  { name: 'Off Days', path: '/off-day', icon: 'insert_invitation', visible: authRoles.subAdmin },

  { label: 'Settings', type: 'label', visible: authRoles.subAdmin },
  { name: 'System Settings', path: '/dashboard/default', icon: 'settings', visible: authRoles.subAdmin },

  // {
  //   name: 'Components',
  //   icon: 'favorite',
  //   badge: { value: '30+', color: 'secondary' },
  //   children: [
  //     { name: 'Auto Complete', path: '/material/autocomplete', iconText: 'A' },
  //     { name: 'Buttons', path: '/material/buttons', iconText: 'B' },
  //     { name: 'Checkbox', path: '/material/checkbox', iconText: 'C' },
  //     { name: 'Dialog', path: '/material/dialog', iconText: 'D' },
  //     { name: 'Expansion Panel', path: '/material/expansion-panel', iconText: 'E' },
  //     { name: 'Form', path: '/material/form', iconText: 'F' },
  //     { name: 'Icons', path: '/material/icons', iconText: 'I' },
  //     { name: 'Menu', path: '/material/menu', iconText: 'M' },
  //     { name: 'Progress', path: '/material/progress', iconText: 'P' },
  //     { name: 'Radio', path: '/material/radio', iconText: 'R' },
  //     { name: 'Switch', path: '/material/switch', iconText: 'S' },
  //     { name: 'Slider', path: '/material/slider', iconText: 'S' },
  //     { name: 'Snackbar', path: '/material/snackbar', iconText: 'S' },
  //     { name: 'Table', path: '/material/table', iconText: 'T' },
  //   ],
  // },
  // {
  //   name: 'Charts',
  //   icon: 'trending_up',
  //   children: [{ name: 'Echarts', path: '/charts/echarts', iconText: 'E' }],
  // },
  // {
  //   name: 'Documentation',
  //   icon: 'launch',
  //   type: 'extLink',
  //   path: 'http://demos.ui-lib.com/matx-react-doc/',
  // },
];
