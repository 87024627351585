import axiosClient from '../axios';

export const statesList = () => axiosClient.get('state');
export const statesCreate = (data) => axiosClient.post('state', data);
export const statesUpdate = (id, data) => axiosClient.put(`state/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const statesUpdateStatus = (data) => axiosClient.post('state-active', data);
export const statesDelete = (id) => axiosClient.delete(`state/${id}`);
