import PARTY_CATEGORY_ACTION_TYPES from './partyCategories.types';

export const PARTY_CATEGORY_INITIAL_STATE = {
  partyCategoriesLists: [],
};

export const PartyCategoriesReducer = (state = PARTY_CATEGORY_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PARTY_CATEGORY_ACTION_TYPES.LOAD_PARTY_CATEGORIES: {
      return { ...state, partyCategoriesLists: payload };
    }
    case PARTY_CATEGORY_ACTION_TYPES.DELETE_PARTY_CATEGORIES: {
      return { ...state, partyCategoriesLists: state.partyCategoriesLists.filter((item, index) => index !== action.payload) };
    }
    default:
      return state;
  }
};
