import CITIES_ACTION_TYPES from './cities.types';

export const CITIES_INITIAL_CITY = {
  citiesLists: [],
};

export const CitiesReducer = (state = CITIES_INITIAL_CITY, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CITIES_ACTION_TYPES.LOAD_CITIES: {
      return { ...state, citiesLists: payload };
    }
    case CITIES_ACTION_TYPES.DELETE_CITIES: {
      return { ...state, citiesLists: state.citiesLists.filter((item, index) => index !== action.payload) };
    }
    default:
      return state;
  }
};
