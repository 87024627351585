import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import StatesCreate from './StatesCreate';
import StatesUpdate from './StatesUpdate';

const States = Loadable(lazy(() => import('./States')));

const statesRoutes = [
  { path: '/states/', element: <States />, auth: authRoles.admin },
  { path: '/states/create', element: <StatesCreate />, auth: authRoles.admin },
  { path: '/states/edit/:stateId', element: <StatesUpdate />, auth: authRoles.admin },
];

export default statesRoutes;
