import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { citiesCreate, citiesList } from "app/api/services/cities";
import { countriesList } from "app/api/services/countries";
import { statesList } from "app/api/services/states";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setCitiesList } from "app/redux/cities/cities.action";
import { selectCities } from "app/redux/cities/cities.selector";
import { setStatesList } from "app/redux/states/states.action";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CitiesForm from "./CitiesForm";

const CitiesCreate = () => {
    const title = 'cities';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [city, setCity] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { updateSnackbar } = useSnackbar();


    const cities = useSelector(selectCities).map(item => item.name.toLowerCase());
    const currentCity = { name: "", country_name: "", country_id: "", city_grade_id: "", default: { label: 'Select State', id: 0 } }
    useEffect(() => {
        try {
            statesList().then((res) => {
                dispatch(setStatesList(res.data.result.data));
            });
            citiesList().then((res) => {
                dispatch(setCitiesList(res.data.result.data));
                setCity(cities);
            });
        } catch (error) {
            console.log(error);
        }
    }, [])

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("state_id", state.state_id)
        formData.append("pin_code", state.pin_code)

        citiesCreate(formData).then((res) => {
            updateSnackbar("success", "City created successfully")
            navigate("/cities");
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "City Create" }]} />
            </Box>
            <SimpleCard title="Cities Form">
                <CitiesForm handleSubmit={handleSubmit} tableData={currentCity} />
            </SimpleCard>
        </Container>
    );
};

export default CitiesCreate;
