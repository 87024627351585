import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import PartyCategoriesCreate from './PartyCategoriesCreate';
import PartyCategoriesUpdate from './PartyCategoriesUpdate';

const PartyCategories = Loadable(lazy(() => import('./PartyCategories')));

const partyCategoriesRoutes = [
  { path: '/party-categories/', element: <PartyCategories />, auth: authRoles.admin },
  { path: '/party-categories/create', element: <PartyCategoriesCreate />, auth: authRoles.admin },
  { path: '/party-categories/edit/:partyCategoryId', element: <PartyCategoriesUpdate />, auth: authRoles.admin },
];

export default partyCategoriesRoutes;
