import {
    Button,
    Grid,
    Icon,
    styled,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));

const PartyCategoriesForm = ({ handleSubmit, tableData}) => {
    
    const [state, setState] = useState({ name: tableData.name });

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    
    const { name } = state;
    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>
                
                <Grid container spacing={6}>
                
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                
                        <TextField
                            type="text"
                            name="name"
                            id="name"
                            value={name || ""}
                            onChange={handleChange}
                            errorMessages={["this field is required", "Party category name alredy exists"]}
                            label="Party Name"
                            validators={["required", "isNameUnique"]}
                        />
                
                    </Grid>

                </Grid>

                <Button color="primary" variant="contained" type="submit">
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default PartyCategoriesForm;
