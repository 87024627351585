import COUNTRY_ACTION_TYPES from './countries.types';

export const setCountriesList = (countries) => {
  return { type: COUNTRY_ACTION_TYPES.LOAD_COUNTRIES, payload: countries };
};
export const deleteAdmin = (deletedCountryIndex) => {
  return { type: COUNTRY_ACTION_TYPES.DELETE_COUNTRIES, payload: deletedCountryIndex };
};
export const updateAdmin = (updatedCountryIndex) => {
  return { type: COUNTRY_ACTION_TYPES.UPDATE_COUNTRIES, payload: updatedCountryIndex };
};
