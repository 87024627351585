import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Select,
    styled,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));

const ExpenseCategoriesForm = ({ handleSubmit, tableData }) => {

    const [state, setState] = useState({ name: tableData.name, limited_by_price: tableData.limited_by_price });

    const [selectValue, setSelectValue] = useState('');

    const { name, limited_by_price } = state;

    const handleCheckboxChange = (event) => {
        setState({ ...state, [event.target.name]: (limited_by_price == 1) ? 0 : 1 });
    };

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };


    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>

                <Grid container>

                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                        <TextField
                            type="text"
                            name="name"
                            id="name"
                            value={name || ""}
                            onChange={handleChange}
                            errorMessages={["this field is required", "Expense Category name alredy exists"]}
                            label="Expense category Name"
                            validators={["required", "isNameUnique"]}
                        />

                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 2 }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={limited_by_price == 1}
                                    onChange={handleCheckboxChange}
                                    value={1}
                                    name="limited_by_price"
                                    id="limited_by_price"
                                    color="primary"

                                />}
                            label="Limited By Price"
                        />

                    </Grid>

                </Grid>

                <Button color="primary" variant="contained" type="submit">
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default ExpenseCategoriesForm;
