import EXPENSE_CATEGORIES_ACTION_TYPES from "./expenseCategories.types";

export const EXPENSE_CATEGORIES_INITIAL_STATE = {
  expenseCategoriesLists: [],
};

export const ExpenseCategoriesReducer = (state = EXPENSE_CATEGORIES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EXPENSE_CATEGORIES_ACTION_TYPES.LOAD_EXPENSE_CATEGORIES: {
      return { ...state, expenseCategoriesLists: payload };
    }
    default:
      return state;
  }
};
