import EMPLOYEE_GRADE_ACTION_TYPES from "./employeeGrades.types";

export const EMPLOYEE_GRADE_INITIAL_STATE = {
  employeeGradesLists: [],
};

export const EmployeeGradesReducer = (state = EMPLOYEE_GRADE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEE_GRADE_ACTION_TYPES.LOAD_EMPLOYEE_GRADE: {
      return { ...state, employeeGradesLists: payload };
    }
    default:
      return state;
  }
};
