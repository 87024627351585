import axiosClient from '../axios';
import qs from 'qs';

export const employeeActiveList = () => axiosClient.get('punchin-location', {
    params: {
      active: 1,
      all_log: 1,
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  });


