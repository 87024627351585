import axiosClient from '../axios';
import qs from 'qs';

export const partyCategoriesList = () => axiosClient.get('party-category');
export const partyCategoriesCreate = (data) => axiosClient.post('party-category', data);
export const partyCategoriesUpdate = (id, data) => axiosClient.put(`party-category/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const partyCategoriesUpdateStatus = (data) => axiosClient.post('party-category-active', data);
export const partyCategoriesDelete = (id) => axiosClient.delete(`party-category/${id}`);
