import { combineReducers } from 'redux';
import { ActiveEmployeeReducer } from '../activeEmployees/activeEmployees.reducer';
import { AdminsReducer } from '../admins/admin.reducer';
import { CitiesReducer } from '../cities/cities.reducer';
import { CountriesReducer } from '../countries/countries.reducer';
import { EmployeeGradesReducer } from '../employeeGrades/employeeGrades.reducer';
import { EmployeesReducer } from '../employees/employees.reducer';
import { EmployeeExpensesReducer } from '../employeeExpenses/employeeExpenses.reducer';
import { ExpenseCategoriesReducer } from '../expenseCategories/expenseCategories.reducer';
import { GradesReducer } from '../grades/grades.reducer';
import { MapReducer } from '../maps/map.reducer';
import { OffDayReducer } from '../offDays/offDays.reducer';
import { PartiesReducer } from '../parties/parties.reducer';
import { PartyCategoriesReducer } from '../partyCategories/partyCategories.reducer';
import { PartyTypesReducer } from '../partyTypes/partyTypes.reducer';
import { StatesReducer } from '../states/states.reducer';
import { ZonesReducer } from '../zones/zones.reducer';
import EcommerceReducer from './EcommerceReducer';
import NavigationReducer from './NavigationReducer';
import NotificationReducer from './NotificationReducer';

const RootReducer = combineReducers({
  countries: CountriesReducer,
  zones: ZonesReducer,
  states: StatesReducer,
  cities: CitiesReducer,
  partyCategories: PartyCategoriesReducer,
  partyTypes: PartyTypesReducer,
  admins: AdminsReducer,
  employees: EmployeesReducer,
  employeeExpenses: EmployeeExpensesReducer,
  parties: PartiesReducer,
  mapPins: MapReducer,
  grades: GradesReducer,
  offDays: OffDayReducer,
  activeEmployees: ActiveEmployeeReducer,
  expenseCategories: ExpenseCategoriesReducer,
  employeeGrades: EmployeeGradesReducer,


  notifications: NotificationReducer,
  navigations: NavigationReducer,
  ecommerce: EcommerceReducer,
});

export default RootReducer;
