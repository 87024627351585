import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import PaymentsUpdate from './PaymentsUpdate';

const Payments = Loadable(lazy(() => import('./Payments')));

const paymentsRoutes = [
  { path: '/payments/', element: <Payments />, auth: authRoles.admin },
  { path: '/payments/update/:paymentId', element: <PaymentsUpdate />, auth: authRoles.admin },
];

export default paymentsRoutes;
