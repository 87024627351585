import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Grid,
    TextField,
} from "@mui/material";
import useSnackbar from "app/hooks/useSnackbar";
import { selectCities } from "app/redux/cities/cities.selector";
import { selectEmployees } from "app/redux/employees/employees.selector";
import { selectPartyCategories } from "app/redux/partyCategories/partyCategories.selector";
import { selectPartyTypes } from "app/redux/partyTypes/partyTypes.selector";
import { Formik } from "formik";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PartiesForm = ({ handleSubmit, initialValues }) => {

    const navigate = useNavigate();

    const { updateSnackbar } = useSnackbar();

    const cities = useSelector(selectCities);

    const partyCategories = useSelector(selectPartyCategories);

    const partyTypes = useSelector(selectPartyTypes);

    const employees = useSelector(selectEmployees);

    const citiesDropdown = cities.map((item) => { return { id: item.id, label: item.name } })

    const partyCategoriesDropdown = partyCategories.map((item) => { return { id: item.id, label: item.name } })

    const partyTypesDropdown = partyTypes.map((item) => { return { id: item.id, label: item.name } })

    const employeesDropdown = employees.map((item) => { return { id: item.id, label: item.name } })

    const handleFormSubmit = async (values, { setFieldError }) => {

        await handleSubmit(values).then((res) => {
            updateSnackbar("success", res.data.message)
            navigate("/parties");
        }).catch((e) => {
            if (e.response.status == 422) {
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])
                );
            }
        });

    };

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
            // validationSchema={ }
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            {/* Firm Name TextField */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    fullWidth
                                    type="text"
                                    name="firm_name"
                                    label="Firm Name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.firm_name}
                                    onChange={handleChange}
                                    helperText={touched.firm_name && errors.firm_name}
                                    error={Boolean(errors.firm_name && touched.firm_name)}
                                />

                            </Grid>

                            {/* Select Employee Field */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={employeesDropdown}
                                    disableClearable
                                    // defaultValue={{label: initialValues.employee_name, id: initialValues.employee_id}}
                                    defaultValue={{ label: initialValues.employee_name, id: initialValues.employee_id }}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setFieldValue("employee_id", newValue.id);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-employee-demo"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Employee Name"
                                            value={initialValues.employee_id || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            helperText={touched.employee_id && errors.employee_id}
                                            error={Boolean(touched.employee_id && errors.employee_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                            {/* Email TextField */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    type="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(errors.email && touched.email)}
                                />
                            </Grid>

                            {/* Contact TextField */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="contact"
                                    label="Contact"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.contact}
                                    onChange={handleChange}
                                    helperText={touched.contact && errors.contact}
                                    error={Boolean(errors.contact && touched.contact)}
                                />
                            </Grid>

                            {/* Address TextField */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="address"
                                    label="Address"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.address}
                                    onChange={handleChange}
                                    helperText={touched.address && errors.address}
                                    error={Boolean(errors.address && touched.address)}
                                />
                            </Grid>

                            {/* Select City Field */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={citiesDropdown}
                                    disableClearable
                                    defaultValue={{ label: initialValues.city_name, id: initialValues.city_id }}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setFieldValue("city_id", newValue.id);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-city-demo"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="City Name"
                                            value={initialValues.city_id || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            helperText={touched.city_id && errors.city_id}
                                            error={Boolean(touched.city_id && errors.city_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                            {/* Select Party Category */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={partyCategoriesDropdown}
                                    disableClearable
                                    defaultValue={{ label: initialValues.party_category_name, id: initialValues.party_category_id }}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setFieldValue("party_category_id", newValue.id);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-party_category-demo"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Party Category Name"
                                            value={initialValues.party_category_id || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            helperText={touched.party_category_id && errors.party_category_id}
                                            error={Boolean(touched.party_category_id && errors.party_category_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                            {/* Select Party Type */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={partyTypesDropdown}
                                    disableClearable
                                    defaultValue={{ label: initialValues.party_type_name, id: initialValues.party_type_id }}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setFieldValue("party_type_id", newValue.id);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id == value.id}
                                    id="controllable-party_type-demo"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Party Type Name"
                                            value={initialValues.party_type_name || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            helperText={touched.party_type_id && errors.party_type_id}
                                            error={Boolean(touched.party_type_id && errors.party_type_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                            {/* Aadhar Number TextField */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    name="aadhar_number"
                                    type="text"
                                    label="Adhaar Number"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.adhaar_number || values.aadhar_number}
                                    onChange={handleChange}
                                    helperText={touched.aadhar_number && errors.aadhar_number}
                                    error={Boolean(errors.aadhar_number && touched.aadhar_number)}
                                />
                            </Grid>

                            {/* GST Number TextField */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    name="gst_number"
                                    type="text"
                                    label="Gst Number"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.gst_number}
                                    onChange={handleChange}
                                    helperText={touched.gst_number && errors.gst_number}
                                    error={Boolean(errors.gst_number && touched.gst_number)}
                                />
                            </Grid>

                            {/* Contact Person Name TextField */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    name="contact_person[0].name"
                                    type="text"
                                    label="Contact Person Name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.contact_person[0].name}
                                    onChange={handleChange}
                                    helperText={(errors.contact_person) ? errors.contact_person[0].name : false}
                                    error={Boolean((errors.contact_person) ? errors.contact_person[0].name : 0)}
                                />
                            </Grid>

                            {/* Contact Person Phone Number TextField */}
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    name="contact_person[0].phone"
                                    type="text"
                                    label="Contact Person Phone"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.contact_person[0].phone}
                                    onChange={handleChange}
                                    helperText={(errors.contact_person) ? errors.contact_person[0].phone : ''}
                                    error={Boolean((errors.contact_person) ? errors.contact_person[0].phone : 0)}
                                />
                            </Grid>

                        </Grid>

                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={false}
                            variant="contained"
                            sx={{ my: 2 }}
                        >
                            Submit
                        </LoadingButton>

                    </form>
                )}
            </Formik>

        </div>
    );
};

export default PartiesForm;
