import { Box, styled, Tab, Tabs } from '@mui/material';
import { employeeDetail } from 'app/api/services/employees';
import { Breadcrumb, MatxLoading, SimpleCard } from 'app/components';
import TabPanel from 'app/components/TabPanel';
import { capitalizeFirstLetter } from 'app/utils/utils';
import React from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EmployeeDetail from './EmployeeDetail';
import EmployeeExpenses from './EmployeeExpenses';
import EmployeeParties from './EmployeeParties';
import EmployeePayments from './EmployeePayments';
import EmployeeVisits from './EmployeeVisits';

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
function EmployeesView() {

    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({})
    const [value, setValue] = React.useState({});

    const { employeeId } = useParams();

    useEffect(() => {
        setLoading(true);
        try {
            employeeDetail(employeeId).then((res) => {
                setState(res.data.result.data[0]);
                setValue(0);
                setLoading(false);
            });
        } catch (error) {
            console.log('error')
            setLoading(false);
        }
    }, [])


    const handleChange = (event, newValue) => {

        console.log(newValue);
        setValue(newValue);
    };
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter('Employee View'), path: "/" + encodeURI('view') }, { name: "Employee Detail" }]} />
            </Box>
            <SimpleCard title="Employees Detail">
                {loading ?
                    <MatxLoading />
                    : <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Profile Detail" {...a11yProps(0)} />
                                <Tab label="Parties" {...a11yProps(1)} />
                                <Tab label="Expenses" {...a11yProps(2)} />
                                <Tab label="Visits" {...a11yProps(3)} />
                                <Tab label="Payments" {...a11yProps(4)} />
                                {/* <Tab label="Attendance" {...a11yProps(5)} /> */}
                                {/* <Tab label="Device Logs" {...a11yProps(6)} /> */}
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <EmployeeDetail data={state} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <EmployeeParties data={state.party} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <EmployeeExpenses data={state.expenses} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <EmployeeVisits data={state.visits} />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <EmployeePayments data={state.payments} />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                            Item Three
                        </TabPanel>
                        <TabPanel value={value} index={6}>
                            Item Three
                        </TabPanel>
                    </>
                }

                {/* <EmployeesForm handleSubmit={handleFormSubmit} initialValues={initialValues} /> */}
            </SimpleCard>
        </Container>)
}

export default EmployeesView