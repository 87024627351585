import { createSelector } from "reselect";

const selectZoneReducer = (state) => {
  return state.zones.zonesLists
};

export const selectZones = createSelector(
  [selectZoneReducer],
  (state) => {
    return state
  }
);