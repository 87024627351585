import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Button,
    Grid,
    Icon,
    styled,
    TextField,
} from "@mui/material";
import { Span } from "app/components/Typography";
import useSnackbar from "app/hooks/useSnackbar";
import { selectAdmins } from "app/redux/admins/admin.selector";
import { selectAdminChildren } from "app/redux/admins/adminChildren.selector";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const AdminsForm = ({ handleSubmit, tableData }) => {

    const [state, setState] = useState({ name: tableData.name, email: tableData.email, parent_id: tableData.parent_id, password: "", confirm_password: "", default: tableData.default, role: tableData.role });
    // console.log(state);

    const navigate = useNavigate();
    const { updateSnackbar } = useSnackbar();


    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
        // console.log(state);
    };

    // useEffect(() => {
    //     ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    //         if (value !== state.password) {
    //             return false;
    //         }
    //         return true;
    //     });
    //     return () => {
    //         ValidatorForm.removeValidationRule("isPasswordMatch");
    //     };
    // }, [state]);

    const handleFormSubmit = async (values, { setFieldError }) => {
        await handleSubmit(values).then((res) => {
            updateSnackbar("success", res.data.message)
            navigate("/admins");
        }).catch((e) => {
            if (e.response.status == 422) {
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])

                );

            }
        });
    };

    const adminChildren = useSelector(selectAdminChildren);
    const admins = useSelector(selectAdmins);

    const suggestion = (adminChildren) ? adminChildren.map(item => {
        return {
            id: item.id,
            label: item.name + " (" + item.email + ")"
        }
    }) : [];

    const suggestions = admins.map((item) => {
        return {
            id: item.id,
            label: item.name + " (" + item.email + ")"
        }
    })
    const roles = [
        { label: 'Admin', id: 'Admin' },
        { label: 'Sub Admin', id: 'Sub Admin' },
    ];
    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
            initialValues={tableData}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                    label="Email"
                                    fullWidth
                                    />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={roles}
                                    disableClearable
                                    defaultValue={state.role}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setState({ ...state, role: newValue.id });
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-admins-role"
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        label="Role"
                                        value={state.role || ""}
                                        variant="outlined"
                                        onChange={handleChange}
                                        helperText={touched.role && errors.role}
                                        error={Boolean(touched.role && errors.role)}
                                        fullWidth
                                        />
                                        )}
                                        />

                            </Grid>
                        </Grid>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    label="Name"
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={suggestion}
                                    disableClearable
                                    defaultValue={state.default}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setState({ ...state, parent_id: newValue.id });
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-admins-demo"
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                            label="Parent Name"
                                            value={state.parent_id || ""}
                                            variant="outlined"
                                            onChange={handleChange}
                                            helperText={touched.parent_id && errors.parent_id}
                                            error={Boolean(touched.parent_id && errors.parent_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                        </Grid>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={values.password || ""}
                                    onChange={handleChange}
                                    label="Password"
                                    helperText={touched.password && errors.password}
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    
                                    />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="password"
                                    name="confirm_password"
                                    id="confirm_password"
                                    value={values.confirm_password || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label="Confirm Password"
                                    helperText={touched.confirm_password && errors.confirm_password}
                                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                                    fullWidth

                                />

                            </Grid>

                        </Grid>

                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={false}
                            variant="contained"
                            sx={{ my: 2 }}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default AdminsForm;
