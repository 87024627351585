import { Paper, Stack, styled, TextField } from '@mui/material'
import { H5, Span } from 'app/components/Typography';
import React, { useState } from 'react'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    width: '100%',
    color: theme.palette.text.secondary,
}));

const ExpenseCategoryNameField = styled(H5)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.H5,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    textAlign: 'center',
    width: '50px',
    color: theme.palette.text.secondary,
}));

const ExpenseCategoriesContainer = ({ errors, touched, formField, handleChange, cityGradeKey, expenseCategoryKey }) => {
    const [value, setValue] = useState(formField.city_grade[cityGradeKey]['expense_categories'][expenseCategoryKey]);

    const handleFormChange = (event) => {
        setValue({ ...value, amount: event.target.value })
        handleChange(event.target.value);
    }

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
        >
            <Item>
                <ExpenseCategoryNameField>

                    {value.expense_category_name}
                </ExpenseCategoryNameField>
                <TextField
                    type="number"
                    name="amount"
                    id={value.expense_category_name + "amount"}
                    value={value.amount || ""}
                    onChange={handleFormChange}
                    errorMessages={["this field is required", "Employee Grade name alredy exists"]}
                    label="Employee Grade Name"
                    helperText={errors && errors.amount}
                    error={Boolean(touched && touched.amount)}

                    validators={["required", "isNameUnique"]}
                />
            </Item>
        </Stack>
    )
}

export default ExpenseCategoriesContainer