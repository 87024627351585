import EMPLOYEE_EXPENSES_ACTION_TYPES from "./employeeExpenses.types";

export const EMPLOYEE_EXPENSES_INITIAL_STATE = {
  employeeExpensesLists: [],
};

export const EmployeeExpensesReducer = (state = EMPLOYEE_EXPENSES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEE_EXPENSES_ACTION_TYPES.LOAD_EMPLOYEE_EXPENSES: {
      return { ...state, employeeExpensesLists: payload };
    }
    default:
      return state;
  }
};
