import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { citiesList } from "app/api/services/cities";
import { employeesCreate, employeesDropdown } from "app/api/services/employees";
import { partiesCreate } from "app/api/services/parties";
import { partyCategoriesList } from "app/api/services/partyCategories";
import { partyTypesList } from "app/api/services/partyTypes";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setCitiesList } from "app/redux/cities/cities.action";
import { setEmployeesList } from "app/redux/employees/employees.action";
import { setPartyCategoriesList } from "app/redux/partyCategories/partyCategories.action";
import { setPartyTypesList } from "app/redux/partyTypes/partyTypes.action";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PartiesForm from "./PartiesForm";

const PartiesCreate = () => {
    const title = 'parties';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { updateSnackbar } = useSnackbar();


    // const employees = useSelector(selectEmployees);
    // const cities = useSelector(selectCities).map((item) => { return { id: item.id, name: item.name.toLowerCase() }});
    var arr = new Map([
        ['name', 'User'],
        ['number', ''],
    ]);

    const initialValues = {
        employee_id: "0",
        employee_name: "Select Employee",
        city_id: "0",
        city_name: "Select City",
        party_type_id: "0",
        party_type_name: "Select Party Type",
        party_category_id: "0",
        party_category_name: "Select Party Category",
        firm_name: "",
        contact: "",
        email: "",
        address: "",
        aadhar_number: "",
        gst_number: "",
        // contact_person_name: "",
        // contact_person_phone: "",
        contact_person: [{ 'name': '', 'phone': '' }]
        // contact_person: {
        //     0: {
        //         name: "test",
        //         number: ""
        //     }
        // }
        // contact_person.{0}.name: "", 
        // contact_person.{0}.number: "", 
    }
    useEffect(() => {
        try {

            employeesDropdown().then((res) => {
                dispatch(setEmployeesList(res.data.result));
            });
            citiesList().then((res) => {
                dispatch(setCitiesList(res.data.result.data));
            });
            partyTypesList().then((res) => {
                dispatch(setPartyTypesList(res.data.result.data));
            });
            partyCategoriesList().then((res) => {
                dispatch(setPartyCategoriesList(res.data.result.data));
            });
        } catch (error) {
            console.log(error);
        }
    }, [])

    const handleFormSubmit = (state) => {
        const response = {};

        // const formData = new FormData();
        // formData.append("employee_id", state.employee_id)
        // formData.append("party_category_id", state.party_category_id)
        // formData.append("party_type_id", state.party_type_id)
        // formData.append("firm_name", state.firm_name)
        // formData.append("email", state.email)
        // formData.append("contact", state.contact)
        // formData.append("address", state.address)
        // formData.append("city_id", state.city_id)
        // formData.append("aadhar_number", state.adhaar_number)
        // formData.append("gst_number", state.gst_number)
        // formData.append("contact_person", JSON.stringify(state.contact_person))

        return partiesCreate(state)
        // .then((res) => {
        //     response = res;
        // })
        // .catch(function (error) {
        //      response =  error.response;

        // });

        // return response;
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Party Create" }]} />
            </Box>
            <SimpleCard title="Parties Form">
                <PartiesForm handleSubmit={handleFormSubmit} initialValues={initialValues} />
            </SimpleCard>
        </Container>
    );
};

export default PartiesCreate;
