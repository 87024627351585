import { Box, Paper, Stack, styled } from '@mui/material'
import { H4, H6 } from 'app/components/Typography';
import React, { useState } from 'react'
import ExpenseCategoriesContainer from './ExpenseCategoriesContainer'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const GradeNameContainer = styled(H6)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.h6,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.primary,
}));

const CityGradesContainer = ({ errors, touched, formField, handleCategoryChange, cityGradeKey }) => {

  // console.log(errors)
  // console.log(touched)


  const [value, setValue] = useState(formField.city_grade[cityGradeKey]);

  const changeHandler = (key, element) => {

    const expenseCategories = value['expense_categories'];

    expenseCategories[key]['amount'] = element;

    const newExpenseCategories = expenseCategories

    setValue({ ...value, expense_categories: newExpenseCategories })
    handleCategoryChange(value)



  }

  return (
    <Stack spacing={2}>
      <Item>
        <GradeNameContainer>
          City Grade:
          {value.city_grade_name}
        </GradeNameContainer>
        {value.expense_categories.map((item, key) => {
          const error = (errors != undefined && errors.expense_categories) ? errors.expense_categories[key] : null;
          const touch = (touched != undefined && touched.expense_categories) ? touched.expense_categories[key] : null;
          return (<ExpenseCategoriesContainer key={key} errors={error} touched={touch} formField={formField} handleChange={(value) => changeHandler(key, value)} cityGradeKey={cityGradeKey} expenseCategoryKey={key} />)
        }
        )}
      </Item>
    </Stack>
  )
}
export default CityGradesContainer