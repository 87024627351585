import PARTY_TYPE_ACTION_TYPES from './partyTypes.types';

export const PARTY_TYPE_INITIAL_STATE = {
  partyTypesLists: [],
};

export const PartyTypesReducer = (state = PARTY_TYPE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PARTY_TYPE_ACTION_TYPES.LOAD_PARTY_TYPES: {
      return { ...state, partyTypesLists: payload };
    }
    case PARTY_TYPE_ACTION_TYPES.DELETE_PARTY_TYPES: {
      return { ...state, partyTypesLists: state.partyTypesLists.filter((item, index) => index !== action.payload) };
    }
    default:
      return state;
  }
};
