import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { gradesCreate, gradesList } from "app/api/services/cityGrade";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setGradesList } from "app/redux/grades/grades.action";
import { selectGrades } from "app/redux/grades/grades.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GradesForm from "./GradesForm";

const GradesCreate = () => {
    const title = 'countries';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [grade, setGrade] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { updateSnackbar } = useSnackbar();



    const grades = useSelector(selectGrades).map(item => item.name.toLowerCase());
    const currentCountry = { name: "", description: "" }
    useEffect(() => {
        try {
            gradesList().then((res) => {
                dispatch(setGradesList(res.data.result.data));
                setGrade(grades);
            });
        } catch (error) {
            console.log(error);
        }
    }, [])


    useEffect(() => {
        ValidatorForm.addValidationRule("isNameUnique", (value) => {
            if (grades.includes(value)) return false;

            return true;
        });
        return () => {
            ValidatorForm.removeValidationRule("isNameUnique");
        };
    }, [grade]);

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("description", state.description)

        gradesCreate(formData).then((res) => {
            updateSnackbar("success", "City Grade created successfully")
            navigate("/grades");
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Grades Create" }]} />
            </Box>
            <SimpleCard title="City Grade Form">
                <GradesForm handleSubmit={handleSubmit} tableData={currentCountry} />
            </SimpleCard>
        </Container>
    );
};

export default GradesCreate;
