import { createSelector } from "reselect";

const selectEmployeeGradesReducer = (state) => {
  return state.employeeGrades.employeeGradesLists
};

export const selectEmployeeGrades = createSelector(
  [selectEmployeeGradesReducer],
  (state) => {
    return state
  }
);