import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import ZonesCreate from './ZonesCreate';
import ZonesUpdate from './ZonesUpdate';

const Zones = Loadable(lazy(() => import('./Zones')));

const zonesRoutes = [
  { path: '/zones/', element: <Zones />, auth: authRoles.admin },
  { path: '/zones/create', element: <ZonesCreate />, auth: authRoles.admin },
  { path: '/zones/edit/:zoneId', element: <ZonesUpdate />, auth: authRoles.admin },
];

export default zonesRoutes;
