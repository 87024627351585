import axiosClient from '../axios';

export const gradesList = () => axiosClient.get('city-grade');

export const gradesCreate = (data) => axiosClient.post('city-grade', data);

export const gradesUpdate = (id, data) => axiosClient.put(`city-grade/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});

export const gradesUpdateStatus = (data) => axiosClient.post('city-grade-active', data);

export const gradesDelete = (id) => axiosClient.delete(`city-grade/${id}`);
