import EMPLOYEE_ACTION_TYPES from './employees.types';

export const EMPLOYEES_INITIAL_STATE = {
  employeesLists: [],
};

export const EmployeesReducer = (state = EMPLOYEES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEE_ACTION_TYPES.LOAD_EMPLOYEES: {
      return { ...state, employeesLists: payload };
    }
    default:
      return state;
  }
};
