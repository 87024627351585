import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { zonesList, zonesUpdate } from "app/api/services/zones";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setZonesList } from "app/redux/zones/zones.action";
import { selectZones } from "app/redux/zones/zones.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ZonesForm from "./ZonesForm";

const ZonesUpdate = () => {
    const title = 'zones';

    const [state, setState] = useState([]);

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { zoneId } = useParams();
    const { updateSnackbar } = useSnackbar();


    const zonesState = useSelector(selectZones);
    const zones = zonesState.map(item => {
        return item.name.toLowerCase()

    });
    const currentZone = zonesState.filter(item => item.id == zoneId);
    const selected = { label: currentZone[0].country_name, id: currentZone[0].id }

    useEffect(() => {
        try {
            zonesList().then((res) => {
                dispatch(setZonesList(res.data.result.data));
                setState(zones);
            });
        } catch (error) {
            console.log(error);
        }
    }, [])


    const handleSubmit = (state) => {

        const formData = {
            "name": state.zone_name,
            "country_id": state.country_id
        }

        zonesUpdate(zoneId, formData).then((res) => {

            updateSnackbar("success", "Zone updated successfully")
            navigate("/zones");

        }).catch(function (error) {

            if (error.response) {
                console.log(error.response);
            }

        });
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "State Update" }]} />
            </Box>
            <SimpleCard title="Zones Form">
                <ZonesForm handleSubmit={handleSubmit} tableData={{ ...currentZone[0], default: selected }} />
            </SimpleCard>
        </Container>
    );
};

export default ZonesUpdate;
