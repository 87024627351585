import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { useEffect } from "react";
import { useState } from "react";

const Map = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC45H8puiP7Gj6ZTyNT0Hobx8RyPLeyYiY&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [places, setPlaces] = useState([])

  useEffect(() => {

    if (Object.keys(props.Pins).length > 0) {
      
      //pins format: 
      
      // [
        // {id: '1', 'employee_id': '12', lat: '212.121', long: '34.1212'}
      // ]
      
      setPlaces(props.Pins)
    }

  }, [props.Pins])

  console.log(places);
  return (
    <GoogleMap
      defaultZoom={3}
      defaultCenter={{ lat: 22.309425, lng: 72.136230 }}
    >

      {
        places.length > 0 && places.map((item) => {
          return (
            <Marker
              key={item.id}
              position={{
                lat: parseFloat(item.lat),
                lng: parseFloat(item.long)
              }} />)
        })
      }
    </GoogleMap>
  )
}
)

export default Map;