import GRADES_ACTION_TYPES from './grades.types';

export const GRADE_INITIAL_STATE = {
  gradesLists: [],
};

export const GradesReducer = (state = GRADE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GRADES_ACTION_TYPES.LOAD_GRADES: {
      return { ...state, gradesLists: payload };
    }
    case GRADES_ACTION_TYPES.DELETE_GRADES: {
      return { ...state, gradesLists: state.gradesLists.filter((item, index) => index !== action.payload) };
    }
    default:
      return state;
  }
};
