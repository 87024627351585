import axiosClient from '../axios';

export const citiesList = () => axiosClient.get('city');
export const citiesCreate = (data) => axiosClient.post('city', data);
export const citiesUpdate = (id, data) => axiosClient.put(`city/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const citiesUpdateStatus = (data) => axiosClient.post('city-active', data);
export const citiesDelete = (id) => axiosClient.delete(`city/${id}`);
