import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const EmployeeLiveMap = Loadable(lazy(() => import('./EmployeeLiveMap')));

const employeeLiveMapRoutes = [
  { path: '/map/', element: <EmployeeLiveMap />, auth: authRoles.admin },
];

export default employeeLiveMapRoutes;
