import axiosClient from '../axios';

export const expenseCategoriesList = () => axiosClient.get('expense-category');
export const expenseCategoriesCreate = (data) => axiosClient.post('expense-category', data);
export const expenseCategoriesUpdate = (id, data) => axiosClient.put(`expense-category/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const expenseCategoriesUpdateStatus = (data) => axiosClient.post('expense-category-active', data);
export const expenseCategoriesDelete = (id) => axiosClient.delete(`expense-category/${id}`);
