import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { adminsList, adminChildrenList } from "app/api/services/admins";
import { citiesList } from "app/api/services/cities";
import { employeesCreate, employeesUpdate, employeesView } from "app/api/services/employees";
import { employeeGradesList } from "app/api/services/employeeGrades";
import { Breadcrumb, MatxLayouts, MatxLoading, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setAdminsList } from "app/redux/admins/admin.action";
import { setCitiesList } from "app/redux/cities/cities.action";
import { setEmployeeGradesList } from "app/redux/employeeGrades/employeeGrades.action";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import EmployeesForm from "./EmployeesForm";
import { setAdminChildrenList } from "app/redux/admins/adminChildren.action";

const EmployeesUpdate = () => {
    const title = 'employees';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const { employeeId } = useParams();

    const [loading, setLoading] = useState(false);


    const dispatch = useDispatch();
    const { updateSnackbar } = useSnackbar();


    const [initialValues, setInitialValues] = useState({
        parent_id: "",
        parent_name: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_no: "",
        password: "",
        confirm_password: "",
        city_id: "",
        city_name: "",
        employee_grade_id: "",
        employee_grade_name: "",
        address: "",
    })
    useEffect(() => {
        setLoading(true);
        try {
            employeesView(employeeId).then((res) => {
                console.log(res.data.result);
                setInitialValues(res.data.result);
                setLoading(false);
                setLoading(false);
            });

            adminsList().then((res) => {
                dispatch(setAdminsList(res.data.result.data));
            });
            adminChildrenList().then((res) => {
                dispatch(setAdminChildrenList(res.data.result));
            });
            citiesList().then((res) => {
                dispatch(setCitiesList(res.data.result.data));
            });
            employeeGradesList().then((res) => {
                dispatch(setEmployeeGradesList(res.data.result.data));
            });
        } catch (error) {
            console.log(error);
        }
    }, [])

    const handleFormSubmit = (state) => {
console.log(state);
        const formData = new FormData();
        formData.append("parent_id", state.parent_id)
        formData.append("first_name", state.first_name)
        formData.append("last_name", state.last_name)
        formData.append("email", state.email)
        formData.append("phone_no", state.phone_no)
        if(state.password != undefined){
            formData.append("password", state.password)
        }
        if(state.confirm_password != undefined){
            formData.append("confirm_password", state.confirm_password)
        }
        formData.append("city_id", state.city_id)

        if(state.employee_grade_id != undefined){
            formData.append("employee_grade_id", state.employee_grade_id)
        } else {
            formData.append("employee_grade_id", state.grade_id)
        }
        
        formData.append("address", state.address)

        return employeesUpdate(employeeId, formData)
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Employee Update" }]} />
            </Box>
            <SimpleCard title="Employees Form">
                {loading ? <MatxLoading /> : <EmployeesForm handleSubmit={handleFormSubmit} initialValues={initialValues} />}
            </SimpleCard>
        </Container>
    );
};

export default EmployeesUpdate;
