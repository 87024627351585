import OFFDAY_ACTION_TYPES from './offDays.types';

export const OFFDAY_INITIAL_STATE = {
  offDayLists: [],
};

export const OffDayReducer = (state = OFFDAY_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case OFFDAY_ACTION_TYPES.LOAD_OFFDAY: {
      return { ...state, offDayLists: payload };
    }
    // case COUNTRIES_ACTION_TYPES.DELETE_COUNTRIES: {
    //   return { ...state, countriesLists: state.countriesLists.filter((item, index) => index !== action.payload) };
    // }
    default:
      return state;
  }
};
