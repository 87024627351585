import axiosClient from '../axios';

export const adminsList = () => axiosClient.get('admins');
export const adminChildrenList = () => axiosClient.get(`descendant`);
export const adminsCreate = (data) => axiosClient.post('admins', data);
export const adminsUpdate = (id, data) => axiosClient.put(`admins/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const adminsUpdateStatus = (data) => axiosClient.post('admins-active', data);
export const adminsDelete = (id) => axiosClient.delete(`admins/${id}`);
