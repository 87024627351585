import MAP_ACTION_TYPES from './map.types';

export const MAP_INITIAL_STATE = {
  mapPinsLists: [],
};

export const MapReducer = (state = MAP_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case MAP_ACTION_TYPES.LOAD_MAPS: {
      return { ...state, mapPinsLists: payload };
    }
    default:
      return state;
  }
};
