import { createSelector } from "reselect";

const selectPartyTypeReducer = (state) => {
  return state.partyTypes.partyTypesLists
};

export const selectPartyTypes = createSelector(
  [selectPartyTypeReducer],
  (state) => {
    return state
  }
);