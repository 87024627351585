import { createSelector } from "reselect";

const selectPartyCategoryReducer = (state) => {
  return state.partyCategories.partyCategoriesLists
};

export const selectPartyCategories = createSelector(
  [selectPartyCategoryReducer],
  (state) => {
    return state
  }
);