import PARTY_TYPE_ACTION_TYPES from './partyTypes.types';

export const setPartyTypesList = (partyTypes) => {
  return { type: PARTY_TYPE_ACTION_TYPES.LOAD_PARTY_TYPES, payload: partyTypes };
};
export const deletePartyTypes = (deletedPartyTypesIndex) => {
  return { type: PARTY_TYPE_ACTION_TYPES.DELETE_PARTY_TYPES, payload: deletedPartyTypesIndex };
};
export const updatePartyTypes = (updatedPartyTypesIndex) => {
  return { type: PARTY_TYPE_ACTION_TYPES.UPDATE_PARTY_TYPES, payload: updatedPartyTypesIndex };
};
