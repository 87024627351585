import { createSelector } from "reselect";

const selectCityReducer = (state) => {
  return state.cities.citiesLists
};

export const selectCities = createSelector(
  [selectCityReducer],
  (state) => {
    return state
  }
);