import axiosClient from '../axios';
import qs from 'qs';

export const employeeGradesList = () => axiosClient.get('employee-grade');

export const employeeGradesCreate = (data) =>
  axiosClient.post('employee-grade', data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const employeeGradesUpdate = (id, data) =>
  axiosClient.put(`employee-grade/${id}?_method=PUT`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const employeeGradesUpdateStatus = (data) => axiosClient.post('employee-grade-active', data);

export const employeeGradesFormData = () => axiosClient.get('create-form-details');

export const employeeGradesUpdateFormData = (id) => axiosClient.get(`update-form-details/${id}`);

export const employeeGradesDelete = (id) => axiosClient.delete(`employee-grade/${id}`);

// export const employeeGradesDropdown = () => axiosClient.get('employee-dropdown');

// export const employeeGradesView = async (id) => await axiosClient.get(`employees/${id}`);
