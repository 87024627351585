import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { employeeGradesCreate, employeeGradesFormData } from "app/api/services/employeeGrades";
import { Breadcrumb, MatxLoading, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EmployeeGradesForm from "./EmployeeGradesForm";

const EmployeeGradesCreate = () => {
    const title = 'employee grades';
    const [initialValues, setInitialValues] = useState({})
    const [loading, setLoading] = useState(false);

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const dispatch = useDispatch();
    const { updateSnackbar } = useSnackbar();

    useEffect(() => {
        setLoading(true);
        try {
            employeeGradesFormData().then((res) => {
                setInitialValues(res.data.result)
                setLoading(false);
            });
        } catch (error) {
            console.log(error);
        }
    }, [])

    const handleFormSubmit = (state) => {
        console.log(state);
        // const formData = new FormData();
        // formData.append("parent_id", state.parent_id)
        // formData.append("first_name", state.first_name)
        // formData.append("last_name", state.last_name)
        // formData.append("email", state.email)
        // formData.append("phone_no", state.phone_no)
        // formData.append("password", state.password)
        // formData.append("confirm_password", state.confirm_password)
        // formData.append("city_id", state.city_id)
        // formData.append("address", state.address)
        // formData.append("grade_id", state.grade_id)

        return employeeGradesCreate(state)
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Employee Create" }]} />
            </Box>
            <SimpleCard title="Employees Form">
                {
                    loading
                        ?
                        <MatxLoading />
                        :
                        <EmployeeGradesForm handleSubmit={handleFormSubmit} initialValues={initialValues} />
                }
            </SimpleCard>
        </Container>
    );
};

export default EmployeeGradesCreate;
