import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
const TreeView = Loadable(lazy(() => import('./TreeView')));

const treeViewRoutes = [
  { path: '/tree/', element: <TreeView />, auth: authRoles.admin },
];

export default treeViewRoutes;
