import { createSelector } from "reselect";

const selectCountryReducer = (state) => {
  return state.countries.countriesLists
};

export const selectCountries = createSelector(
  [selectCountryReducer],
  (state) => {
    return state
  }
);