import axiosClient from '../axios';
import qs from 'qs';

export const employeesList = (filters, sort, per_page, page) => axiosClient.get('employees', {
  params: {
    filter: filters,
    sort: sort,
    per_page: per_page,
    page: page,
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { encode: false });
  },
});

export const employeesCreate = (data) => axiosClient.post('employees', data);

export const employeesUpdate = (id, data) => axiosClient.post(`employees/${id}?_method=PUT`, data);

export const employeesUpdateStatus = (data) => axiosClient.post('employee-active', data);

export const employeesResetDevice = (data) => axiosClient.post('resetdevice', data);

export const employeesDelete = (id) => axiosClient.delete(`employees/${id}`);

export const employeesDropdown = () => axiosClient.get('employee-dropdown');

export const employeesView = async (id) => await axiosClient.get(`employees/${id}`);

export const employeeDetail = async (id) => await axiosClient.get(`employee-view?id=${id}`);



