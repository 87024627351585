import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import CountriesCreate from './CountriesCreate';
import CountriesUpdate from './CountriesUpdate';

const Countries = Loadable(lazy(() => import('./Countries')));

const countriesRoutes = [
  { path: '/countries/', element: <Countries />, auth: authRoles.admin },
  { path: '/countries/create', element: <CountriesCreate />, auth: authRoles.admin },
  { path: '/countries/edit/:countryId', element: <CountriesUpdate />, auth: authRoles.admin },
];

export default countriesRoutes;
