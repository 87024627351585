import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import ActiveEmployeeView from './ActiveEmployeeView';

const ActiveEmployee = Loadable(lazy(() => import('./ActiveEmployees')));

const activeEmployeesRoutes = [
  { path: '/active-employees/', element: <ActiveEmployee />, auth: authRoles.admin },
  { path: '/active-employee/view/:punchId', element: <ActiveEmployeeView />, auth: authRoles.admin },

];

export default activeEmployeesRoutes;
