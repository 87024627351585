import {
    Button,
    Grid,
    Icon,
    styled,
    TextField,
} from "@mui/material";
import { Span } from "app/components/Typography";
import useSnackbar from "app/hooks/useSnackbar";
import { Formik } from "formik";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate } from "react-router-dom";


const CountriesForm = ({ handleSubmit, tableData }) => {
    const navigate = useNavigate();
    
    const { updateSnackbar } = useSnackbar();

    const [state, setState] = useState({ country_name: tableData.name, country_code: tableData.country_code });

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleFormSubmit = async (values, { setFieldError }) => {
        await handleSubmit(values).then((res) => {
            updateSnackbar("success", res.data.message)
            navigate("/countries");
        }).catch((e) => {
            if (e.response.status == 422) {
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])

                );

            }
        });
    };

    const { country_name, country_code } = state;
    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={state}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="country_name"
                                    id="country_name"
                                    value={values.country_name || ""}
                                    onChange={handleChange}
                                    label="Country Name"
                                    helperText={touched.country_name && errors.country_name}
                                    error={Boolean(touched.country_name && errors.country_name)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    sx={{ mb: 4 }}
                                    type="text"
                                    name="country_code"
                                    label="Country Code"
                                    onChange={handleChange}
                                    value={values.country_code || ""}
                                    helperText={touched.country_code && errors.country_code}
                                    error={Boolean(touched.country_code && errors.country_code)}
                                    fullWidth
                                    />

                            </Grid>

                        </Grid>

                        <Button color="primary" variant="contained" type="submit">
                            <Icon>send</Icon>
                            <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                        </Button>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default CountriesForm;
