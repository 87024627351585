import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import AdminsCreate from './AdminsCreate';
import AdminsUpdate from './AdminsUpdate';

const Admins = Loadable(lazy(() => import('./Admins')));

const adminsRoutes = [
  { path: '/admins/', element: <Admins />, auth: authRoles.admin },
  { path: '/admins/create', element: <AdminsCreate />, auth: authRoles.admin },
  { path: '/admins/edit/:adminId', element: <AdminsUpdate />, auth: authRoles.admin },
];

export default adminsRoutes;
