import axiosClient from '../axios';

// export default {
//     // returns list of all the item
//     // return one specific item
//     detail: (id) => axios.get(`country/${id}`),
//     // delete specific item
//     delete: (id) => axios.delete(`country/${id}`),
//     // update specific item
//     update: (id) => axios.put(`country/${id}`),
//   };

export const partyTypesList = () => axiosClient.get('party-type');
export const partyTypesCreate = (data) => axiosClient.post('party-type', data);
export const partyTypesUpdate = (id, data) => axiosClient.put(`party-type/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const partyTypesUpdateStatus = (data) => axiosClient.post('party-type-active', data);
export const partyTypesDelete = (id) => axiosClient.delete(`party-type/${id}`);
