import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    FormControl,
    Grid,
    Input,
    InputLabel,
    Paper,
    styled,
    TextField,
} from "@mui/material";
import useSnackbar from "app/hooks/useSnackbar";
import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CityGradesContainer from "./formComponents/CityGradesContainer";

const EmployeeGradesForm = ({ handleSubmit, initialValues }) => {
    const [formValue, setFormValue] = useState(initialValues);

    const navigate = useNavigate();

    const { updateSnackbar } = useSnackbar();

    const handleFormSubmit = async (values, { setFieldError }) => {
        console.log(formValue);
        await handleSubmit(formValue).then((res) => {
            updateSnackbar("success", res.data.message)
            navigate("/employee-grades");
        }).catch((e) => {
            if (e.response.status == 422) {
                // console.log(e.response.data.result);
                // Object.entries(e.response.data.result).forEach(
                //     ([key, value]) => console.log(key)
                // )
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])

                );

            }
        });
    };

    const handleFormChange = (event) => {
        event.persist();
        setFormValue({ ...formValue, 'name': event.target.value });
    };

    const categoryChangeHandler = (key, value) => {
        const cityGrades = formValue['city_grade'];
        const cityGrade = formValue['city_grade'][key];

    };

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={formValue}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid
                            direction="column"
                            container
                            spacing={1}
                        >
                            <Grid lg={12} md={12} sm={12} xs={12} sx={{
                                backgroundColor: '#fff',
                                padding: 1,
                                margin: 1,
                                textAlign: 'center'
                            }} >

                                {/* <Item> */}
                                <TextField
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={formValue.name || ""}
                                    onChange={handleFormChange}
                                    errorMessages={["this field is required", "Employee Grade name alredy exists"]}
                                    label="Employee Grade Name"
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                    validators={["required", "isNameUnique"]}
                                />
                            </Grid>
                            <Grid>

                                {/* </Item>
                            <Item> */}

                                {formValue.city_grade && formValue.city_grade.length > 0 && formValue.city_grade.map((value, key) => {
                                    const error = (errors && errors.city_grade) ? errors.city_grade[key] : null;
                                    const touch = (touched && touched.city_grade) ? touched.city_grade[key] : null;
                                    return <CityGradesContainer errors = {error} touched = {touch} formField={formValue} handleCategoryChange={(item) => categoryChangeHandler(key, item)} key={key} cityGradeKey={key} />
                                })}
                            </Grid>
                            {/* </Item> */}
                        </Grid>
                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={false}
                            variant="contained"
                            sx={{ my: 2 }}
                        >
                            Submit
                        </LoadingButton>

                    </form>
                )}
            </Formik>

        </div>
    );
};

export default EmployeeGradesForm;
