import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import PartiesCreate from './PartiesCreate';

const Parties = Loadable(lazy(() => import('./Parties')));
const PartiesUpdate = Loadable(lazy(() => import('./PartiesUpdate')));

const partiesRoutes = [
  { path: '/parties/', element: <Parties />, auth: authRoles.admin },
  { path: '/parties/create', element: <PartiesCreate />, auth: authRoles.admin },
  { path: '/parties/edit/:partyId', element: <PartiesUpdate />, auth: authRoles.admin },
];

export default partiesRoutes;
