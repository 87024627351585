import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { partyTypesCreate, partyTypesList } from "app/api/services/partyTypes";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setPartyTypesList } from "app/redux/partyTypes/partyTypes.action";
import { selectPartyTypes } from "app/redux/partyTypes/partyTypes.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PartyTypesForm from "./PartyTypesForm";

const PartyTypesCreate = () => {
    const title = 'Party Types';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [partyType, setPartyType] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {updateSnackbar} = useSnackbar();



    const partyTypes = useSelector(selectPartyTypes).map( item => item.name.toLowerCase());
    const currentPartyType = {name:""}
    useEffect(() => {
        try {
            partyTypesList().then((res) => {
                dispatch(setPartyTypesList(res.data.result.data));
                setPartyType(partyTypes);
            });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    
    useEffect(() => {
        ValidatorForm.addValidationRule("isNameUnique", (value) => {
            if (partyTypes.includes(value)) return false;
            
            return true;
        });
        return () => {
            ValidatorForm.removeValidationRule("isNameUnique");
        };
    }, [partyType]);
    
    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        
        partyTypesCreate(formData).then((res) => {
            updateSnackbar("success", "Party Type created successfully")
            navigate("/party-types");
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Party Type Create" }]} />
            </Box>
            <SimpleCard title="Party Category Form">
                <PartyTypesForm handleSubmit={handleSubmit} tableData={currentPartyType}/>
            </SimpleCard>
        </Container>
    );
};

export default PartyTypesCreate;
