import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { countriesUpdate, countriesList } from "app/api/services/countries";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setCountriesList } from "app/redux/countries/countries.action";
import { selectCountries } from "app/redux/countries/countries.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CountriesForm from "./CountriesForm";

const CountriesUpdate = () => {
    const title = 'countries';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [country, setCountry] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { countryId } = useParams();
    const { updateSnackbar } = useSnackbar();

    
    const countriesState = useSelector(selectCountries);
    const countries = countriesState.map( item => {
        if(item.id != countryId){
            return item.name.toLowerCase()
        }
    });
    const countriesCode = countriesState.map( item =>  {
        if(item.id != countryId){
            return item.country_code.toLowerCase()
        }
        
    });
    const currentCountry = countriesState.filter( item => item.id == countryId);
    
    useEffect(() => {
        try {
            countriesList().then((res) => {
                dispatch(setCountriesList(res.data.result.data));
                setCountry(countries);
            });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    
    
    const handleSubmit = (state) => {
        
        const formData = {
            "name": state.country_name, 
            "country_code": state.country_code
        }

        return countriesUpdate(countryId, formData);
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Countries Create" }]} />
            </Box>
            <SimpleCard title="Countries Form">
                <CountriesForm handleSubmit={handleSubmit} tableData={currentCountry[0]}/>
            </SimpleCard>
        </Container>
    );
};

export default CountriesUpdate;
