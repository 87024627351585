import PARTY_CATEGORY_ACTION_TYPES from './partyCategories.types';

export const setPartyCategoriesList = (partyCategories) => {
  return { type: PARTY_CATEGORY_ACTION_TYPES.LOAD_PARTY_CATEGORIES, payload: partyCategories };
};
export const deletePartyCategories = (deletedPartyCategoriesIndex) => {
  return { type: PARTY_CATEGORY_ACTION_TYPES.DELETE_PARTY_CATEGORIES, payload: deletedPartyCategoriesIndex };
};
export const updatePartyCategories = (updatedPartyCategoriesIndex) => {
  return { type: PARTY_CATEGORY_ACTION_TYPES.UPDATE_PARTY_CATEGORIES, payload: updatedPartyCategoriesIndex };
};
