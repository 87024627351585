import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { AlertProvider } from './contexts/AlertContext';
import { FormDialogProvider } from './contexts/FormDialogContext';

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store}>
      <SettingsProvider>
        <SnackbarProvider>
          <AlertProvider>
            <FormDialogProvider>
                <MatxTheme>
                  <AuthProvider>
                    {content}
                  </AuthProvider>
                </MatxTheme>
            </FormDialogProvider>
          </AlertProvider>
        </SnackbarProvider>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
