import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import EmployeesCreate from './EmployeesCreate';
import EmployeesUpdate from './EmployeesUpdate';
import EmployeesView from './EmployeesView';

const Employees = Loadable(lazy(() => import('./Employees')));

const employeesRoutes = [
  { path: '/employees/', element: <Employees />, auth: authRoles.admin },
  { path: '/employees/create', element: <EmployeesCreate />, auth: authRoles.admin },
  { path: '/employees/edit/:employeeId', element: <EmployeesUpdate />, auth: authRoles.admin },
  { path: '/employees/view/:employeeId', element: <EmployeesView />, auth: authRoles.admin },
];

export default employeesRoutes;
