import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { citiesList, citiesUpdate } from "app/api/services/cities";
import { gradesList } from "app/api/services/cityGrade";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setCitiesList } from "app/redux/cities/cities.action";
import { selectCities } from "app/redux/cities/cities.selector";
import { setGradesList } from "app/redux/grades/grades.action";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CitiesForm from "./CitiesForm";

const CitiesUpdate = () => {
    const title = 'cities';

    const [state, setState] = useState([]);

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cityId } = useParams();
    const { updateSnackbar } = useSnackbar();

    const citiesState = useSelector(selectCities);
    const cities = citiesState.map( item => {
            return item.name.toLowerCase()
        
    });
    const currentCity = citiesState.filter( item => item.id == cityId);
    console.log(currentCity);
    const selected = { label: currentCity[0].state_name, id: currentCity[0].state_id }
    const selectedGrade = { label: currentCity[0].city_grade_name, id: currentCity[0].city_grade_id }
    
    useEffect(() => {
        try {
            citiesList().then((res) => {
                dispatch(setCitiesList(res.data.result.data));
                setState(cities);
            });
            gradesList().then((res) => {
                // console.log(res);
                dispatch(setGradesList(res.data.result.data));
                // setLoading(false);
              });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    
    const handleSubmit = (state) => {
        
        const formData = {
            "name": state.name, 
            "state_id": state.state_id,
            "pin_code": state.pin_code
        }

        citiesUpdate(cityId, formData).then((res) => {
        
            updateSnackbar("success", "City updated successfully")
            navigate("/cities");
        
        }).catch(function (error) {
        
            if (error.response) {
                console.log(error.response);
            }
        
        });
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "City Update" }]} />
            </Box>
            <SimpleCard title="Cities Form">
                <CitiesForm handleSubmit={handleSubmit} tableData={{...currentCity[0], default: selected, cityGrade: selectedGrade, city_grade_id: selectedGrade.id}}/>
            </SimpleCard>
        </Container>
    );
};

export default CitiesUpdate;
