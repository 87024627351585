import { createSelector } from "reselect";

const selectAdminReducer = (state) => {
  return state.admins.adminsLists
};

export const selectAdmins = createSelector(
  [selectAdminReducer],
  (state) => {
    return state
  }
);