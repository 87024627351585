import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const OffDay = Loadable(lazy(() => import('./OffDay')));

const offDayRoutes = [
  { path: '/off-day/', element: <OffDay />, auth: authRoles.admin },
];

export default offDayRoutes;
