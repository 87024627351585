import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { countriesList } from "app/api/services/countries";
import { statesCreate, statesList } from "app/api/services/states";
import { zonesList } from "app/api/services/zones";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setCountriesList } from "app/redux/countries/countries.action";
import { setStatesList } from "app/redux/states/states.action";
import { selectStates } from "app/redux/states/states.selector";
import { setZonesList } from "app/redux/zones/zones.action";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StatesForm from "./StatesForm";

const StatesCreate = () => {
    const title = 'states';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [state, setState] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { updateSnackbar } = useSnackbar();


    const states = useSelector(selectStates).map(item => item.name.toLowerCase());
    const currentState = { name: "", country_name: "", country_id: "", default: {label: 'select zone', id: 0} }
    useEffect(() => {
        try {
            zonesList().then((res) => {
                dispatch(setZonesList(res.data.result.data));
            });
            statesList().then((res) => {
                dispatch(setStatesList(res.data.result.data));
                setState(states);
            });
        } catch (error) {
            console.log(error);
        }
    }, [])


    useEffect(() => {
        ValidatorForm.addValidationRule("isNameUnique", (value) => {
            if (statesList.includes(value)) return false;

            return true;
        });
        return () => {
            ValidatorForm.removeValidationRule("isNameUnique");
        };
    }, [state]);

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.state_name)
        formData.append("zone_id", state.zone_id)
        statesCreate(formData).then((res) => {
            updateSnackbar("success", "State created successfully")
            navigate("/states");
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "State Create" }]} />
            </Box>
            <SimpleCard title="States Form">
                <StatesForm handleSubmit={handleSubmit} tableData={currentState} />
            </SimpleCard>
        </Container>
    );
};

export default StatesCreate;
