import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { partyCategoriesList, partyCategoriesUpdate } from "app/api/services/partyCategories";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setPartyCategoriesList } from "app/redux/partyCategories/partyCategories.action";
import { selectPartyCategories } from "app/redux/partyCategories/partyCategories.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PartyCategoriesForm from "./PartyCategoriesForm";

const PartyCategoriesUpdate = () => {
    const title = 'Party Categories';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [partyCategory, setPartyCategory] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { partyCategoryId } = useParams();
    const { updateSnackbar } = useSnackbar();

    
    const partyCategoriesState = useSelector(selectPartyCategories);
    const partyCategories = partyCategoriesState.map( item => {
        if(item.id != partyCategoryId){
            return item.name.toLowerCase()
        }
    });
    const currentPartyCategory = partyCategoriesState.filter( item => item.id == partyCategoryId);
    useEffect(() => {
        try {
            partyCategoriesList().then((res) => {
                dispatch(setPartyCategoriesList(res.data.result.data));
                setPartyCategory(partyCategories);
            });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    
    useEffect(() => {
        ValidatorForm.addValidationRule("isNameUnique", (value) => {
            if (partyCategories.includes(value)) return false;
            
            return true;
        });
        return () => {
            ValidatorForm.removeValidationRule("isNameUnique");
        };
    }, [partyCategory]);
    
    const handleSubmit = (state) => {
        
        const formData = {
            "name": state.name, 
        }

        partyCategoriesUpdate(partyCategoryId, formData).then((res) => {
        
            updateSnackbar("success", "Party Category updated successfully")
            navigate("/party-categories");
        
        }).catch(function (error) {
        
            if (error.response) {
                console.log(error.response);
            }
        
        });
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Party Category Create" }]} />
            </Box>
            <SimpleCard title="Party Category Form">
                <PartyCategoriesForm handleSubmit={handleSubmit} tableData={currentPartyCategory[0]}/>
            </SimpleCard>
        </Container>
    );
};

export default PartyCategoriesUpdate;
