import {
    Autocomplete,
    Button,
    Grid,
    Icon,
    styled,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { selectGrades } from "app/redux/grades/grades.selector";
import { selectStates } from "app/redux/states/states.selector";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useSelector } from "react-redux";
const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));


const CitiesForm = ({ handleSubmit, tableData }) => {

    console.log(tableData);
    const [state, setState] = useState({
        pin_code: tableData.pin_code,
        name: tableData.name,
        state_id: tableData.state_id,
        city_grade_id: tableData.city_grade_id,
        country_name: "dsad",
        default: tableData.default,
        cityGrade: tableData.cityGrade
    });

    useEffect(() => { console.log(state); }, [state])

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const states = useSelector(selectStates);

    const cityGrade = useSelector(selectGrades);
    // console.log(cityGrade);

    const suggestions = states.map((item) => { return { id: item.id, label: item.name } })

    const cityGradeOptions = cityGrade.map((item) => { return { id: item.id, label: item.name } })
    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>

                <Grid container spacing={6}>

                    <Grid item lg={6} md={6} sm={6} xs={6} sx={{ mt: 2 }}>

                        <TextField
                            type="text"
                            name="name"
                            id="city_name"
                            value={state.name || ""}
                            onChange={handleChange}
                            errorMessages={["This field is required"]}
                            label="City Name"
                            validators={["required"]}
                        />

                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                        <Autocomplete
                            options={cityGradeOptions}
                            disableClearable
                            defaultValue={state.cityGrade}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setState({ ...state, city_grade_id: newValue.id });
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            id="controllable-city-grade-demo"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="City Grade"
                                    value={state.city_grade_id || ""}
                                    variant="outlined"
                                    onChange={handleChange}
                                    errorMessages={["this field is required"]}
                                    validators={["required"]}
                                    fullWidth
                                />
                            )}
                        />

                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                        <Autocomplete
                            options={suggestions}
                            disableClearable
                            defaultValue={state.default}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setState({ ...state, state_id: newValue.id });
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            id="controllable-cities-demo"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="State Name"
                                    value={state.state_id || ""}
                                    variant="outlined"
                                    onChange={handleChange}
                                    errorMessages={["this field is required"]}
                                    validators={["required"]}
                                    fullWidth
                                />
                            )}
                        />

                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                        <TextField
                            type="text"
                            name="pin_code"
                            id="pin_code"
                            value={state.pin_code || ""}
                            onChange={handleChange}
                            errorMessages={["this field is required"]}
                            label="Pin Code"
                            validators={["required"]}
                        />

                    </Grid>


                </Grid>

                <Button color="primary" variant="contained" type="submit" >
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default CitiesForm;
