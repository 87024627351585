import AuthGuard from 'app/auth/AuthGuard';
import chartsRoute from 'app/views/charts/ChartsRoute';
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import NotFound from 'app/views/sessions/NotFound';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import activeEmployeesRoutes from './views/activeEmployees/activeEmployeesRoutes';
import adminsRoutes from './views/admins/AdminsRoutes';
import citiesRoutes from './views/cities/CitiesRoutes';
import countriesRoutes from './views/countries/CountriesRoutes';
import employeeLiveMapRoutes from './views/employeeLiveMap/employeeLiveMapRoutes';
import employeesRoutes from './views/employees/employeesRoutes';
import employeeExpensesRoutes from './views/employeeExpenses/employeeExpensesRoutes';
import employeeVisitRoutes from './views/employeeVisits/employeeVisitRoutes';
import gradesRoutes from './views/cityGrades/GradesRoutes';
import offDayRoutes from './views/offDay/offDayRoutes';
import partiesRoutes from './views/parties/partiesRoutes';
import partyCategoriesRoutes from './views/partyCategories/PartyCategoriesRoutes';
import partyTypesRoutes from './views/partyTypes/PartyTypesRoutes';
import paymentsRoutes from './views/payments/paymentsRoutes';
import statesRoutes from './views/states/StatesRoutes';
import treeViewRoutes from './views/treeView/TreeViewRoutes';
import zonesRoutes from './views/zones/ZonesRoutes';
import expenseCategoriesRoutes from './views/expenseCategories/expenseCategoriesRoutes';
import employeeGradesRoutes from './views/employeeGrades/employeeGradesRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...adminsRoutes,
      ...countriesRoutes,
      ...zonesRoutes,
      ...statesRoutes,
      ...citiesRoutes,
      ...partyCategoriesRoutes,
      ...partyTypesRoutes,
      ...employeesRoutes,
      ...employeeExpensesRoutes,
      ...partiesRoutes,
      ...employeeVisitRoutes,
      ...offDayRoutes,
      ...employeeLiveMapRoutes,
      ...treeViewRoutes,
      ...gradesRoutes,
      ...paymentsRoutes,
      ...activeEmployeesRoutes,
      ...expenseCategoriesRoutes,
      ...employeeGradesRoutes
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
