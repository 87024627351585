import { Box, styled } from '@mui/material';
import { Breadcrumb } from 'app/components';
import { capitalizeFirstLetter } from 'app/utils/utils';
import React from 'react'

const PaymentsUpdate = () => {

    const title = 'Payment'

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + encodeURI(title) }, { name: title + " Payment Update" }]} />
            </Box>
        </Container>
    )
}

export default PaymentsUpdate
