import COUNTRIES_ACTION_TYPES from './countries.types';

export const COUNTRIES_INITIAL_STATE = {
  countriesLists: [],
};

export const CountriesReducer = (state = COUNTRIES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case COUNTRIES_ACTION_TYPES.LOAD_COUNTRIES: {
      return { ...state, countriesLists: payload };
    }
    case COUNTRIES_ACTION_TYPES.DELETE_COUNTRIES: {
      return { ...state, countriesLists: state.countriesLists.filter((item, index) => index !== action.payload) };
    }
    default:
      return state;
  }
};
