import axiosClient from '../axios';
import qs from 'qs';

export const partiesList = (filters, sort, per_page, page) => axiosClient.get('party', {
    params: {
      filter: filters,
      sort: sort,
      per_page: per_page,
      page: page,
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  });

export const partiesCreate = (data) => axiosClient.post('party', data, {
  headers: {
      'Content-Type': 'application/json',
  }
});

export const partiesUpdate = (id, data) => axiosClient.put(`party/${id}`, data, {
  headers: {
      'Content-Type': 'application/json',
  }
});

export const partiesUpdateStatus = (data) => axiosClient.post('party-active', data);

export const partiesDelete = (id) => axiosClient.delete(`party/${id}`);

export const partiesView = async (id) => await axiosClient.get(`party/${id}`);

export const partiesDropdown = () => axiosClient.get('party-dropdown');

