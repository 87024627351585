import { createSelector } from "reselect";

const selectExpenseCategoriesReducer = (state) => {
  return state.expenseCategories.expenseCategoriesLists
};

export const selectExpenseCategories = createSelector(
  [selectExpenseCategoriesReducer],
  (state) => {
    return state
  }
);