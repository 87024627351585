import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
// import CitiesCreate from './CitiesCreate';
// import CitiesUpdate from './CitiesUpdate';

const EmployeeExpenses = Loadable(lazy(() => import('./EmployeeExpenses')));

const employeeExpensesRoutes = [
    { path: '/employee-expenses/', element: <EmployeeExpenses />, auth: authRoles.admin },
    //   { path: '/cities/create', element: <CitiesCreate />, auth: authRoles.admin },
    //   { path: '/cities/edit/:cityId', element: <CitiesUpdate />, auth: authRoles.admin },
];

export default employeeExpensesRoutes;
