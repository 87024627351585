import { createSelector } from "reselect";

const selectAdminChildrenListReducer = (state) => {
  return state.admins.adminChildrenLists
};

const flatten = arr => arr.flatMap(({ children, ...o }) => [o, ...flatten(children)]);

export const selectAdminChildren = createSelector(
  [selectAdminChildrenListReducer],
  (state) => {
    if (state !== undefined) {
      state = flatten([state]);
      state = state.filter(item => (item.role == 'Sub Admin' || item.role == 'Admin' || item.role == 'Super Admin'));
    }
    // console.log(state);
    return state
  }
);