import { createSelector } from "reselect";

const selectEmployeeReducer = (state) => {
  return state.employees.employeesLists
};

export const selectEmployees = createSelector(
  [selectEmployeeReducer],
  (state) => {
    return state
  }
);