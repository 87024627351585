import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { adminsCreate, adminsList, adminChildrenList } from "app/api/services/admins";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setAdminsList } from "app/redux/admins/admin.action";
import { setAdminChildrenList } from "app/redux/admins/adminChildren.action";
import { selectAdmins } from "app/redux/admins/admin.selector";
import { selectAdminChildren } from "app/redux/admins/adminChildren.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminsForm from "./AdminsForm";

const AdminsCreate = () => {
    const title = 'admins';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [admin, setAdmin] = useState([]);

    const dispatch = useDispatch();


    const admins = useSelector(selectAdmins).map(item => item.email.toLowerCase());
    const adminChildrens = useSelector(selectAdminChildren);
    const currentAdmin = { name: "", email: "" }
    useEffect(() => {
        try {
            adminsList().then((res) => {
                dispatch(setAdminsList(res.data.result.data));
                setAdmin(admins);
            });
        } catch (error) {
            console.log(error);
        }
    }, [])

    useEffect(() => {
        try {
            adminChildrenList().then((res) => {
                dispatch(setAdminChildrenList(res.data.result));
            });
        } catch (error) {
            console.log(error);
        }
    }, [])

    // useEffect(() => {
    //     ValidatorForm.addValidationRule("isEmailUnique", (value) => {
    //         if (admins.includes(value)) return false;

    //         return true;
    //     });

    //     return () => {
    //         ValidatorForm.removeValidationRule("isEmailUnique");
    //     };
    // }, [admin]);

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("email", state.email)
        formData.append("parent_id", state.parent_id)
        formData.append("password", state.password)
        formData.append("confirm_password", state.confirm_password)
        formData.append("role", state.role)

        console.log(formData);
        return adminsCreate(formData);
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Admins Create" }]} />
            </Box>
            <SimpleCard title="Admins Form">
                <AdminsForm handleSubmit={handleSubmit} tableData={currentAdmin} />
            </SimpleCard>
        </Container>
    );
};

export default AdminsCreate;
