import axiosClient from '../axios';

// export default {
//     // returns list of all the item
//     // return one specific item
//     detail: (id) => axios.get(`country/${id}`),
//     // delete specific item
//     delete: (id) => axios.delete(`country/${id}`),
//     // update specific item
//     update: (id) => axios.put(`country/${id}`),
//   };

export const countriesList = () => axiosClient.get('country');
export const countriesCreate = (data) => axiosClient.post('country', data);
export const countriesUpdate = (id, data) => axiosClient.put(`country/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const countriesUpdateStatus = (data) => axiosClient.post('country-active', data);
export const countriesDelete = (id) => axiosClient.delete(`country/${id}`);
