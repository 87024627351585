import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { partyCategoriesCreate, partyCategoriesList } from "app/api/services/partyCategories";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setPartyCategoriesList } from "app/redux/partyCategories/partyCategories.action";
import { selectPartyCategories } from "app/redux/partyCategories/partyCategories.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PartyCategoriesForm from "./PartyCategoriesForm";

const PartyCategoriesCreate = () => {
    const title = 'Party categories';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [partyCategory, setPartyCategory] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {updateSnackbar} = useSnackbar();



    const partyCategories = useSelector(selectPartyCategories).map( item => item.name.toLowerCase());
    const currentPartyCategory = {name:""}
    useEffect(() => {
        try {
            partyCategoriesList().then((res) => {
                dispatch(setPartyCategoriesList(res.data.result.data));
                setPartyCategory(partyCategories);
            });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    
    useEffect(() => {
        ValidatorForm.addValidationRule("isNameUnique", (value) => {
            if (partyCategories.includes(value)) return false;
            
            return true;
        });
        return () => {
            ValidatorForm.removeValidationRule("isNameUnique");
        };
    }, [partyCategory]);
    
    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        
        partyCategoriesCreate(formData).then((res) => {
            console.log(res);
            updateSnackbar("success", "Party Category created successfully")
            navigate("/party-categories");
        }).catch(function (error) {
            if (error.response) {
            }
        });
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Party Category Create" }]} />
            </Box>
            <SimpleCard title="Party Category Form">
                <PartyCategoriesForm handleSubmit={handleSubmit} tableData={currentPartyCategory}/>
            </SimpleCard>
        </Container>
    );
};

export default PartyCategoriesCreate;
