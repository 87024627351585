import STATES_ACTION_TYPES from './states.types';

export const STATES_INITIAL_STATE = {
  statesLists: [],
};

export const StatesReducer = (state = STATES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case STATES_ACTION_TYPES.LOAD_STATES: {
      return { ...state, statesLists: payload };
    }
    case STATES_ACTION_TYPES.DELETE_STATES: {
      return { ...state, statesLists: state.statesLists.filter((item, index) => index !== action.payload) };
    }
    default:
      return state;
  }
};
