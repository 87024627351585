import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { adminsList, adminChildrenList } from "app/api/services/admins";
import { citiesList } from "app/api/services/cities";
import { employeeGradesList } from "app/api/services/employeeGrades";
import { employeesCreate, employeesDropdown } from "app/api/services/employees";
import { gradesList } from "app/api/services/cityGrade";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setAdminsList } from "app/redux/admins/admin.action";
import { setAdminChildrenList } from "app/redux/admins/adminChildren.action";
import { setCitiesList } from "app/redux/cities/cities.action";
import { setEmployeesList } from "app/redux/employees/employees.action";
import { setEmployeeGradesList } from "app/redux/employeeGrades/employeeGrades.action";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import EmployeesForm from "./EmployeesForm";

const EmployeesCreate = () => {
    const title = 'employees';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));


    const dispatch = useDispatch();
    const { updateSnackbar } = useSnackbar();


    // const employees = useSelector(selectEmployees);
    // const cities = useSelector(selectCities).map((item) => { return { id: item.id, name: item.name.toLowerCase() }});
    const initialValues = {
        parent_id: "0",
        parent_name: "Select Parent",
        first_name: "",
        last_name: "",
        email: "",
        phone_no: "",
        password: "",
        confirm_password: "",
        city_id: "0",
        city_name: "Select City",
        employee_grade_id: "0",
        employee_grade_name: "Select Employee Grade",
        address: "",
    }
    useEffect(() => {
        try {

            adminsList().then((res) => {
                dispatch(setAdminsList(res.data.result.data));
            });
            adminChildrenList().then((res) => {
                dispatch(setAdminChildrenList(res.data.result));
            });
            citiesList().then((res) => {
                dispatch(setCitiesList(res.data.result.data));
            });
            employeeGradesList().then((res) => {
                dispatch(setEmployeeGradesList(res.data.result.data));
            });
        } catch (error) {
            console.log(error);
        }
    }, [])

    const handleFormSubmit = (state) => {

        const formData = new FormData();
        formData.append("parent_id", state.parent_id)
        formData.append("first_name", state.first_name)
        formData.append("last_name", state.last_name)
        formData.append("email", state.email)
        formData.append("phone_no", state.phone_no)
        if (state.password !== undefined) {
            formData.append("password", state.password)
        }
        if (state.confirm_password !== undefined) {
            formData.append("confirm_password", state.confirm_password)
        }
        formData.append("city_id", state.city_id)
        formData.append("address", state.address)
        formData.append("grade_id", state.grade_id)

        return employeesCreate(formData)
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Employee Create" }]} />
            </Box>
            <SimpleCard title="Employees Form">
                <EmployeesForm handleSubmit={handleFormSubmit} initialValues={initialValues} />
            </SimpleCard>
        </Container>
    );
};

export default EmployeesCreate;
