import { createSelector } from "reselect";

const selectActiveEmployeeReducer = (state) => {
  console.log(state);
  return state.activeEmployees.activeEmployeeLists
};

export const selectActiveEmployee = createSelector(
  [selectActiveEmployeeReducer],
  (state) => {
    return state
  }
);