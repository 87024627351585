import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import CitiesCreate from './CitiesCreate';
import CitiesUpdate from './CitiesUpdate';

const Cities = Loadable(lazy(() => import('./Cities')));

const citiesRoutes = [
  { path: '/cities/', element: <Cities />, auth: authRoles.admin },
  { path: '/cities/create', element: <CitiesCreate />, auth: authRoles.admin },
  { path: '/cities/edit/:cityId', element: <CitiesUpdate />, auth: authRoles.admin },
];

export default citiesRoutes;
