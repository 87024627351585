import {
    Autocomplete,
    Button,
    Grid,
    Icon,
    styled,
} from "@mui/material";
import { Span } from "app/components/Typography";
import { selectZones } from "app/redux/zones/zones.selector";
import { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useSelector } from "react-redux";
const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: "16px",
}));


const StatesForm = ({ handleSubmit, tableData }) => {

    const [state, setState] = useState({state_name: tableData.name, zone_id: tableData.zone_id, zone_name: "", default: tableData.default });
    console.log(state);

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const zones = useSelector(selectZones);
    console.log(zones)

    const suggestions = zones.map((item) => { return { id: item.id, label: item.name } })
    return (
        <div>
            <ValidatorForm onSubmit={() => handleSubmit(state)} onError={() => null}>

                <Grid container spacing={6}>

                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                        <TextField
                            type="text"
                            name="state_name"
                            id="state_name"
                            value={state.state_name || ""}
                            onChange={handleChange}
                            errorMessages={["this field is required"]}
                            label="State Name"
                            validators={["required"]}
                        />

                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                        <Autocomplete
                            options={suggestions}
                            disableClearable
                            defaultValue={state.default}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                setState({ ...state, zone_id: newValue.id });
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            id="controllable-states-demo"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Zone Name"
                                    value={state.zone_id || ""}
                                    variant="outlined"
                                    onChange={handleChange}
                                    errorMessages={["this field is required"]}
                                    validators={["required"]}
                                    fullWidth
                                />
                            )}
                        />

                    </Grid>

                </Grid>

                <Button color="primary" variant="contained" type="submit">
                    <Icon>send</Icon>
                    <Span sx={{ pl: 1, textTransform: "capitalize" }}>Submit</Span>
                </Button>
            </ValidatorForm>
        </div>
    );
};

export default StatesForm;
