import React, { createContext, useState } from 'react';

const FormDialogContext = createContext({
  state: {
    open: false,
    // handleSubmit: () => {}
  },
  updateState: () => {},
});

export const FormDialogProvider = ({ children }) => {
  const [currentState, setCurrentState] = useState({
    open: false,
    // handleSubmit: () => {}
  });

  const handleUpdateFormDialog = (onSubmit) => {
    const newState = { 
        open: !currentState.open,
        //  handleSubmit: onSubmit 
        };
    setCurrentState(newState);
  };

  return (
    <FormDialogContext.Provider
      value={{
        state: currentState,
        updateState: handleUpdateFormDialog,
      }}
    >
      {children}
    </FormDialogContext.Provider>
  );
};

export default FormDialogContext;
