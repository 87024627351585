import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { expenseCategoriesList, expenseCategoriesUpdate } from "app/api/services/expenseCategories";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setExpenseCategoriesList } from "app/redux/expenseCategories/expenseCategories.action";
import { selectExpenseCategories } from "app/redux/expenseCategories/expenseCategories.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ExpenseCategoriesForm from "./ExpenseCategoriesForm";

const ExpenseCategoriesUpdate = () => {
    const title = 'expense Categories';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [expenseCategory, setExpenseCategory] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { expenseCategoryId } = useParams();
    const { updateSnackbar } = useSnackbar();

    
    const expenseCategoriesState = useSelector(selectExpenseCategories);
    const expenseCategories = expenseCategoriesState.map( item => {
        if(item.id != expenseCategoryId){
            return item.name.toLowerCase()
        }
    });

    const currentExpenseCategory = expenseCategoriesState.filter( item => item.id == expenseCategoryId);

    
    useEffect(() => {
        try {
            expenseCategoriesList().then((res) => {
                dispatch(setExpenseCategoriesList(res.data.result.data));
                setExpenseCategory(expenseCategoriesState);
            });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    
    useEffect(() => {
        ValidatorForm.addValidationRule("isNameUnique", (value) => {
            if (expenseCategories.includes(value)) return false;
            
            return true;
        });
        return () => {
            ValidatorForm.removeValidationRule("isNameUnique");
        };
    }, [expenseCategories]);
    
    const handleSubmit = (state) => {
        
        const formData = {
            "name": state.name, 
            "limited_by_price": state.limited_by_price
        }

        expenseCategoriesUpdate(expenseCategoryId, formData).then((res) => {
        
            updateSnackbar("success", "Expense Category updated successfully")
            navigate("/expense-categories");
        
        }).catch(function (error) {
        
            if (error.response) {
                console.log(error.response);
            }
        
        });
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Countries Create" }]} />
            </Box>
            <SimpleCard title="Expense Categories Form">
                <ExpenseCategoriesForm handleSubmit={handleSubmit} tableData={currentExpenseCategory[0]}/>
            </SimpleCard>
        </Container>
    );
};

export default ExpenseCategoriesUpdate;
