import { createSelector } from "reselect";

const selectStateReducer = (state) => {
  return state.states.statesLists
};

export const selectStates = createSelector(
  [selectStateReducer],
  (state) => {
    return state
  }
);