import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { adminsList, adminsUpdate } from "app/api/services/admins";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setAdminsList } from "app/redux/admins/admin.action";
import { selectAdmins } from "app/redux/admins/admin.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AdminsForm from "./AdminsForm";

const AdminsUpdate = () => {
    const title = 'admins';

    const [state, setState] = useState([]);

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { adminId } = useParams();
    const { updateSnackbar } = useSnackbar();

    const adminsState = useSelector(selectAdmins);
    const admins = adminsState.map( item => {
        if(item.id != adminId){
            return item.email.toLowerCase()
        } 
        
    });
    const currentAdmin = adminsState.filter( item => item.id == adminId);
    const selected = { label: currentAdmin[0].name+' ('+currentAdmin[0].email+')', id: currentAdmin[0].id }
    
    useEffect(() => {
        try {
            adminsList().then((res) => {
                dispatch(setAdminsList(res.data.result.data));
                setState(admins);
            });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    // useEffect(() => {
    //     ValidatorForm.addValidationRule("isEmailUnique", (value) => {
    //         if (admins.includes(value)) return false;
            
    //         return true;
    //     });
    //     return () => {
    //         ValidatorForm.removeValidationRule("isEmailUnique");
    //     };
    // }, [admins]);
    

    
    const handleSubmit = (state) => {
        
        const formData = {
            "name": state.name, 
            "email": state.email, 
            "password": state.password, 
            "confirm_password": state.confirm_password, 
            "parent_id": state.parent_id,
        }
        
        return adminsUpdate(adminId, formData);
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Admin Update" }]} />
            </Box>
            <SimpleCard title="Admins Form">
                <AdminsForm handleSubmit={handleSubmit} tableData={{...currentAdmin[0], default: selected}}/>
            </SimpleCard>
        </Container>
    );
};

export default AdminsUpdate;
