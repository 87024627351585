import ZONES_ACTION_TYPES from './zones.types';

export const ZONES_INITIAL_STATE = {
  zonesLists: [],
};

export const ZonesReducer = (state = ZONES_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ZONES_ACTION_TYPES.LOAD_ZONES: {
      return { ...state, zonesLists: payload };
    }
    default:
      return state;
  }
};
