import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import EmployeeGradesCreate from './EmployeeGradesCreate';
import EmployeeGradesUpdate from './EmployeeGradesUpdate';

const EmployeeGrades = Loadable(lazy(() => import('./EmployeeGrades')));

const employeeGradesRoutes = [
  { path: '/employee-grades/', element: <EmployeeGrades />, auth: authRoles.admin },
  { path: '/employee-grades/create', element: <EmployeeGradesCreate />, auth: authRoles.admin },
  { path: '/employee-grades/edit/:employeeGradeId', element: <EmployeeGradesUpdate />, auth: authRoles.admin },
  // { path: '/employees/view/:employeeId', element: <EmployeesView />, auth: authRoles.admin },
];

export default employeeGradesRoutes;
