import { styled } from "@mui/material";
import { Box, Container } from "@mui/system";
import { countriesCreate, countriesList } from "app/api/services/countries";
import { Breadcrumb, SimpleCard } from "app/components";
import useSnackbar from "app/hooks/useSnackbar";
import { setCountriesList } from "app/redux/countries/countries.action";
import { selectCountries } from "app/redux/countries/countries.selector";
import { capitalizeFirstLetter } from "app/utils/utils";
import { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CountriesForm from "./CountriesForm";

const CountriesCreate = () => {
    const title = 'countries';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const [country, setCountry] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {updateSnackbar} = useSnackbar();



    const countries = useSelector(selectCountries).map( item => item.name.toLowerCase());
    const countriesCode = useSelector(selectCountries).map( item => item.country_code.toLowerCase());
    const currentCountry = {country_name:"", country_code:""}
    useEffect(() => {
        try {
            countriesList().then((res) => {
                dispatch(setCountriesList(res.data.result.data));
                setCountry(countries);
            });
        } catch (error) {   
            console.log(error);
        }
    }, [])

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", (state.country_name == undefined) ? "" : state.country_name)
        formData.append("country_code", state.country_code)

        return countriesCreate(formData);
    };
    
    
    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Countries Create" }]} />
            </Box>
            <SimpleCard title="Countries Form">
                <CountriesForm handleSubmit={handleSubmit} tableData={currentCountry}/>
            </SimpleCard>
        </Container>
    );
};

export default CountriesCreate;
