import { createSelector } from "reselect";

const selectGradeReducer = (state) => {
  return state.grades.gradesLists
};

export const selectGrades = createSelector(
  [selectGradeReducer],
  (state) => {
    return state
  }
);