import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
import ExpenseCategoriesCreate from './ExpenseCategoriesCreate';
import ExpenseCategoriesUpdate from './ExpenseCategoriesUpdate';

const ExpenseCategories = Loadable(lazy(() => import('./ExpenseCategories')));

const expenseCategoriesRoutes = [
  { path: '/expense-categories/', element: <ExpenseCategories />, auth: authRoles.admin },
  { path: '/expense-categories/create', element: <ExpenseCategoriesCreate />, auth: authRoles.admin },
  { path: '/expense-categories/edit/:expenseCategoryId', element: <ExpenseCategoriesUpdate />, auth: authRoles.admin },
];

export default expenseCategoriesRoutes;
