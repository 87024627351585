import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Grid,
    TextField,
} from "@mui/material";
import useSnackbar from "app/hooks/useSnackbar";
import { selectAdmins } from "app/redux/admins/admin.selector";
import { selectAdminChildren } from "app/redux/admins/adminChildren.selector";
import { selectCities } from "app/redux/cities/cities.selector";
import { selectEmployeeGrades } from "app/redux/employeeGrades/employeeGrades.selector";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const EmployeesForm = ({ handleSubmit, initialValues }) => {
    console.log(initialValues);

    const [state, setState] = useState({
        parent_id: initialValues.parent_id,
        parent_name: initialValues.parent_name,
        employee_grade_id: initialValues.employee_grade_id,
        employee_grade_name: initialValues.employee_grade_name,
        first_name: initialValues.first_name,
        last_name: initialValues.last_name,
        phone_no: initialValues.phone_no,
        email: initialValues.email,
        city_id: initialValues.city_id,
        city_name: initialValues.city_name,
        address: initialValues.address,
        password: initialValues.password,
        confirm_password: initialValues.confirm_password,
    });

    const navigate = useNavigate();

    const { updateSnackbar } = useSnackbar();

    const cities = useSelector(selectCities);

    const admins = useSelector(selectAdmins);

    const adminChildren = useSelector(selectAdminChildren);

    const employeeGrades = useSelector(selectEmployeeGrades);

    const citiesDropdown = cities.map((item) => { return { id: item.id, label: item.name } })

    const adminsDropdown = admins.map((item) => { return { id: item.id, label: item.name } })

    const employeeGradesDropdown = employeeGrades.map((item) => { return { id: item.id, label: item.name } })

    const adminChildrenDropdown = (adminChildren) ? adminChildren.map((item) => { return { id: item.id, label: item.name } }) : [];

    useEffect(() => { console.log(adminChildrenDropdown) }, [adminChildrenDropdown])
    const handleFormSubmit = async (values, { setFieldError }) => {
        await handleSubmit(values).then((res) => {
            updateSnackbar("success", res.data.message)
            navigate("/employees");
        }).catch((e) => {
            if (e.response.status == 422) {
                console.log(e.response.data.result);
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])

                );

            }
        });
    };

    useEffect(() => { console.log(state); }, [state])

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={adminChildrenDropdown}
                                    disableClearable
                                    defaultValue={{ label: state.parent_name, id: state.parent_id }}
                                    // defaultValue={state.parent_id}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setFieldValue("parent_id", newValue.id);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-parent-demo"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Parent Name"
                                            value={values.parent_id || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            helperText={touched.parent_id && errors.parent_id}
                                            error={Boolean(touched.parent_id && errors.parent_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={employeeGradesDropdown}
                                    disableClearable
                                    defaultValue={{ label: state.employee_grade_name, id: state.employee_grade_id }}
                                    // defaultValue={state.employee_grade_id}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setFieldValue("grade_id", newValue.id);

                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-employee_grade-demo"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Grade Name"
                                            value={values.employee_grade_id || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            helperText={touched.employee_grade_id && errors.employee_grade_id}
                                            error={Boolean(touched.employee_grade_id && errors.employee_grade_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    fullWidth
                                    type="text"
                                    name="first_name"
                                    label="First Name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.first_name}
                                    onChange={handleChange}
                                    helperText={touched.first_name && errors.first_name}
                                    error={Boolean(errors.first_name && touched.first_name)}
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="last_name"
                                    label="Last Name"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.last_name}
                                    onChange={handleChange}
                                    helperText={touched.last_name && errors.last_name}
                                    error={Boolean(errors.last_name && touched.last_name)}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    type="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(errors.email && touched.email)}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="phone_no"
                                    label="Phone No"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.phone_no}
                                    onChange={handleChange}
                                    helperText={touched.phone_no && errors.phone_no}
                                    error={Boolean(errors.phone_no && touched.phone_no)}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    name="password"
                                    id="password"
                                    type="password"
                                    label="Password"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.password}
                                    onChange={handleChange}
                                    helperText={touched.password && errors.password}
                                    error={Boolean(errors.password && touched.password)}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    name="confirm_password"
                                    id="confirm_password"
                                    type="password"
                                    label="Confirm Password"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.confirm_password}
                                    onChange={handleChange}
                                    helperText={touched.confirm_password && errors.confirm_password}
                                    error={Boolean(errors.confirm_password && touched.confirm_password)}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    fullWidth
                                    name="address"
                                    type="text"
                                    label="Address"
                                    variant="outlined"
                                    onBlur={handleBlur}
                                    value={values.address}
                                    onChange={handleChange}
                                    helperText={touched.address && errors.address}
                                    error={Boolean(errors.address && touched.address)}
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <Autocomplete
                                    options={citiesDropdown}
                                    disableClearable
                                    defaultValue={{ label: state.city_name, id: state.city_id }}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        setFieldValue("city_id", newValue.id);
                                        // setState({ ...state, city_id: newValue.id });
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-city-demo"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="City Name"
                                            value={values.city_id || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            variant="outlined"
                                            helperText={touched.city_id && errors.city_id}
                                            error={Boolean(touched.city_id && errors.city_id)}
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>

                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={false}
                            variant="contained"
                            sx={{ my: 2 }}
                        >
                            Submit
                        </LoadingButton>

                    </form>
                )}
            </Formik>

        </div>
    );
};

export default EmployeesForm;
